import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Layout from "./components/AppLayout/AppLayout";
import Dashboard from "./components/Dashboard-Con/Dashboard";
import ViewClient from "./components/Dashboard-Con/ViewClient";
import Settings from "./components/Dashboard-Con/Settings";
import SocialMediaForm from "./components/Dashboard-Con/SocialMediaForm";
import ClientForm from "./components/Dashboard-Con/ClientForm";
import Support from "./components/Dashboard-Con/Support";
import Login from "./components/AdminUsers/Login";
import AdminLogin from "./components/AdminUsers/AdminLogin";
import AdminDashboard from "./components/AdminUsers/AdminDashboard";
import AddUserForm from "./components/AdminUsers/AddUserForm";
import UserList from "./components/AdminUsers/UserList";
import PrivateRoute from "./components/PrivateRoute"; // Ensure this is properly implemented
import AdminPrivateRoute from "./components/AdminPrivateRoute";
import ViewSocialMedia from "./components/Dashboard-Con/ViewSocialMedia";
import SoftwareForm from "./components/Dashboard-Con/SoftwareForm";
import ViewSoftware from "./components/Dashboard-Con/ViewSoftware";
import EventForm from "./components/Dashboard-Con/EventForm";
import ViewEvent from "./components/Dashboard-Con/ViewEvent";
import AddTask from "./components/Dashboard-Con/TaskForm";
import ResetPassword from "./components/AdminUsers/reset";
import ViewTask from "./components/Dashboard-Con/ViewTask";
import AddReport from "./components/Dashboard-Con/AddReport";
import ViewReport from "./components/Dashboard-Con/ViewReport";
import Profile from "./components/AppLayout/Profile";

const App = () => {


  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} /> {/* Add this line */}

        {/* Protected admin Routes */}
        <Route element={<AdminPrivateRoute />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/adduser" element={<AddUserForm />} />
          <Route path="/admin/viewusers" element={<UserList />} />
        </Route>
        
        {/* Protected Routes */}
        <Route element={<PrivateRoute/>}> 
          <Route element={<Layout />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/ViewClient" element={<ViewClient />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/SocialMediaForm" element={<SocialMediaForm />} />
            <Route path="/ViewSocialMedia" element={<ViewSocialMedia />} />
            <Route path="/clientForm" element={<ClientForm />} />
            <Route path="/support" element={<Support />} />
            <Route path="/AddSoftware" element={<SoftwareForm />} />
            <Route path="/ViewSoftware" element={<ViewSoftware />} />
            <Route path="/AddEvent" element={<EventForm />} />
            <Route path="/ViewEvent" element={<ViewEvent />} />
            <Route path="/AddTask" element={<AddTask />} />
            <Route path="/ViewTask" element={<ViewTask />} />
            <Route path="/AddReport" element={<AddReport />} />
            <Route path="/ViewReport" element={<ViewReport />} />
          </Route>
        </Route>
        
        {/* Redirects or 404 */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
