import React, { useEffect, useState, useCallback } from 'react';
import {jwtDecode} from 'jwt-decode'; // Adjust if not default export
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import defaultImage from './user.png';
import Cookies from 'js-cookie';
import { FaStar, FaRegStar, FaTimes } from 'react-icons/fa';

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [imageExists, setImageExists] = useState(true);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progressData, setProgressData] = useState([]);

  // Function to handle the opening and closing of the modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to fetch feedback data from backend
  const fetchProgressData = useCallback(async (username) => {
    try {
      const response = await fetch(`${API_BASE_URL}/feedback/username/${username}`);
      const data = await response.json();
      setProgressData(data);
    } catch (error) {
      console.error('Error fetching feedback data:', error);
    }
  }, []);

  const checkImageExists = useCallback(async (imagePath) => {
    if (!imagePath) {
      setImageExists(false);
      return;
    }
    try {
      const res = await fetch(`${API_BASE_URL}/${imagePath}`, { method: 'HEAD' });
      setImageExists(res.ok);
    } catch (error) {
      console.error('Error checking image:', error);
      setImageExists(false);
    }
  }, []);

  const fetchProfileData = useCallback(async (username) => {
    try {
      const response = await fetch(`${API_BASE_URL}/profileDetails/profile/username/${username}`);
      if (response.ok) {
        const data = await response.json();
        setProfileData(data);
        checkImageExists(data.profileImage);
      } else {
        console.error('Failed to fetch profile data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [checkImageExists]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;
      fetchProfileData(username); // Fetch profile data
      fetchProgressData(username); // Fetch progress/feedback data
    } else {
      navigate('/login');
    }
  }, [navigate, fetchProfileData, fetchProgressData]);

  if (!profileData) {
    return <div className="text-center text-lg font-bold">Loading...</div>;
  }

  



  // Function to format the date to the required format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options); // 'Nov 2024', for example
  };
  // Profile Info for rendering
  const profileInfo = [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6f22d3b931dbd3209fd858790e22fc4b49caa1bbe854feeb81e4af8546857d0b?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6", label: "Joining Date", value: formatDate(profileData.empJoiningDate) },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5c389ff2da33585eead8369ce748451883f0891f864a9bd3fa37fb58cb18d148?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6", label: "Blood Group", value: profileData.bloodGroup },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c70c1116dc73930042f4c7deae25be5ab101c5b7fe90a6091c8e379bdf3924dd?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6", label: "Phone Number", value: profileData.phone },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/66f9dc3cfe9f3f85ab013ca5b0ab3645581bc6b908ab9cab2dedbd9fd6d72190?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6", label: "Date of Birth", value: formatDate(profileData.dob) },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b3a1163baaa1914a704b08535059799aacc1cf78e7016d47ef6b10cb92ebe46c?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6", label: "Office Mail", value: profileData.officialEmail },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b3a1163baaa1914a704b08535059799aacc1cf78e7016d47ef6b10cb92ebe46c?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6", label: "Personal Mail", value: profileData.personalEmail },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5c6a189795f00dce03e6e57e56e5861e8e77ddbda0f1b5466a5f595fb9d11351?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6", label: "Address", value: profileData.address },
  ];

  return (
    <div className="flex justify-center md:mt-8  items-center">
      <section className="flex flex-col items-center self-center px-10 py-4 ml-2.5 border border-gray-200 bg-white rounded-[20px] shadow-[0px_10px_30px_rgba(226,236,249,0.5)] w-full max-w-[600px] max-md:px-5 max-md:mt-8">

        {/* Profile Image and Info */}
        <div className="w-28 h-28 mb-4">
          <img
            src={imageExists ? `${API_BASE_URL}/${profileData.profileImage}` : defaultImage}
            alt="Profile"
            className="w-full h-full object-cover rounded-full border-4 border-[#006CB5] shadow-md"
          />
        </div>
        <h2 className="text-xl font-semibold text-[#006CB5]">{profileData.name}</h2>
        <p className="mt-1 text-base text-neutral-700">{profileData.designation}</p>

        {/* Monthly Progress Button */}
        <div className="flex justify-center w-full mt-3">
          <button
            onClick={toggleModal} // Toggle modal visibility on click
            className="flex items-center justify-center bg-[#006CB5] rounded-xl shadow-sm w-full max-w-[180px] min-h-[40px] text-white font-semibold transition-colors duration-200"
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8ae9a3d53fa73461260059cf7c99190181ab172fdb41ed6c20c7caeeb8807954?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6"
              alt="Feedback Icon"
              className="w-6 h-6 object-contain mr-2 filter invert brightness-0"
            />
            Monthly Progress
          </button>
        </div>



      
        <>
          {/* Modal - Show Monthly Progress Table */}
          
{isModalOpen && (
  <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex justify-center items-center z-50">
    <div className="bg-white p-4 rounded-lg shadow-lg w-[800px] max-w-full relative"> {/* Added relative positioning */}
      {/* Close Icon in the Top Right Corner */}
      <button
        onClick={toggleModal} // Close the modal
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 focus:outline-none"
      >
        <FaTimes size={24} />
      </button>

      {/* Title */}
      <h3 className="text-2xl font-semibold text-[#006CB5] mb-4">Performance Record</h3>

      {/* Table for Showing Monthly Progress */}
      <div className="overflow-x-auto">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className="text-left border-t border-b border-gray-300">
              <th className="px-6 py-3 text-sm font-medium text-gray-600 text-left">Rating</th>
              <th className="px-6 py-3 text-sm font-medium text-gray-600 text-left">Comment</th>
              <th className="px-6 py-3 text-sm font-medium text-gray-600 text-center">Months</th>
              <th className="px-6 py-3 text-sm font-medium text-gray-600 text-center">Year</th>
            </tr>
          </thead>

          <tbody>
            {progressData.length > 0 ? (
              progressData.map((item, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm text-left">
                    {[...Array(5)].map((_, starIndex) => (
                      starIndex < item.rating ? (
                        <FaStar key={starIndex} className="inline text-yellow-500" />
                      ) : (
                        <FaRegStar key={starIndex} className="inline text-yellow-500" />
                      )
                    ))}
                  </td>
                  <td className="px-6 py-4 text-sm text-left">{item.feedback}</td>
                  <td className="px-6 py-4 text-sm text-center">{item.month}</td> {/* Align 'Month' to center */}
                  <td className="px-6 py-4 text-sm text-center">{item.year}</td> {/* Align 'Year' to center */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="px-6 py-4 text-sm text-center">No feedback data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}
        </>

        {/* Profile Info Grid */}
        <div className="grid grid-cols-1 md:pl-12 md:ml-0 md:grid-cols-2 gap-6 mt-6 w-full">
          <div className="flex flex-col gap-3">
            {profileInfo.slice(0, 4).map((info, index) => (
              <div key={index} className="flex gap-3 items-start">
                <img
                  loading="lazy"
                  src={info.icon}
                  alt=""
                  className="object-contain shrink-0 w-[25px] h-[25px]"
                />
                <div className="flex flex-col">
                  <span className="self-start text-xs tracking-normal text-gray-400">{info.label}</span>
                  <span className="mt-1 text-sm font-medium tracking-normal text-zinc-800">{info.value}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col gap-3">
            {profileInfo.slice(4, 7).map((info, index) => (
              <div key={index} className="flex gap-3 items-start">
                <img
                  loading="lazy"
                  src={info.icon}
                  alt=""
                  className="object-contain shrink-0 w-[25px] h-[25px]"
                />
                <div className="flex flex-col">
                  <span className="self-start text-xs tracking-normal text-gray-400">{info.label}</span>
                  <span className="mt-1 text-sm font-medium tracking-normal text-zinc-800">{info.value}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
