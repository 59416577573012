import React, { useState } from "react";
import axios from "axios";
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';


const ClientInformationForm = () => {
  const [showPassword, setShowPassword] = useState(true);

  // Domain state
  // const [clientName, setClientName] = useState('');
  const [clientName, setClientName] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [projectStartDate, setProjectStartDate] = useState("");
  const [projectAssignTo, setProjectAssignTo] = useState("");
  const [domainName, setDomainName] = useState("");
  const [hostingName, sethostingName] = useState("");
  const [technology, settechnology] = useState("");
  const [domainValidity, setDomainValidity] = useState("");
  const [domainDateOfPurchase, setDomainDateOfPurchase] = useState("");
  const [domainDateOfExpiry, setDomainDateOfExpiry] = useState("");
  const handleDomainValidityChange = (event) => {
    setDomainValidity(event.target.value);
    calculateExpiryDate(
      event.target.value,
      domainDateOfPurchase,
      setDomainDateOfExpiry
    );
  };

  const handleDomainDateOfPurchaseChange = (event) => {
    setDomainDateOfPurchase(event.target.value);
    calculateExpiryDate(
      domainValidity,
      event.target.value,
      setDomainDateOfExpiry
    );
  };

  // hosting state
  const [hostingValidity, sethostingValidity] = useState("");
  const [hostingDateOfPurchase, sethostingDateOfPurchase] = useState("");
  const [hostingDateOfExpiry, sethostingDateOfExpiry] = useState("");

  const handlehostingValidityChange = (event) => {
    sethostingValidity(event.target.value);
    calculateExpiryDate(
      event.target.value,
      hostingDateOfPurchase,
      sethostingDateOfExpiry
    );
  };

  const handlehostingDateOfPurchaseChange = (event) => {
    sethostingDateOfPurchase(event.target.value);
    calculateExpiryDate(
      hostingValidity,
      event.target.value,
      sethostingDateOfExpiry
    );
  };

  const calculateExpiryDate = (validity, purchaseDate, setExpiryDate) => {
    if (validity && purchaseDate) {
      const purchaseDateObj = new Date(purchaseDate);
      const expiryDateObj = new Date(purchaseDateObj);

      if (validity === "1") {
        expiryDateObj.setFullYear(purchaseDateObj.getFullYear() + 1);
      } else if (validity === "2") {
        expiryDateObj.setFullYear(purchaseDateObj.getFullYear() + 2);
      } else if (validity === "3") {
        expiryDateObj.setFullYear(purchaseDateObj.getFullYear() + 3);
      } else if (validity === "4") {
        expiryDateObj.setFullYear(purchaseDateObj.getFullYear() + 4);
      } else if (validity === "5") {
        expiryDateObj.setFullYear(purchaseDateObj.getFullYear() + 5);
      }

      setExpiryDate(expiryDateObj.toISOString().split("T")[0]);
    } else {
      setExpiryDate("");
    }
  };

  // Project Status state
  const [projectStatus, setProjectStatus] = useState({
    Website: false,
    SocialMedia: false,
    Closed: false,
  });

  const handleProjectStatusChange = (event) => {
    const { name, checked } = event.target;

    if (name === "Closed" && checked) {
      setProjectStatus({
        Website: false,
        SocialMedia: false,
        Closed: true,
      });
    } else {
      setProjectStatus({
        ...projectStatus,
        [name]: checked,
        Closed: false, // Ensure "Closed" is deselected if any other option is selected
      });
    }
  };

  // Hosting state
  const [hostingStatus, setHostingStatus] = useState({
    cs24: false,
    client: false,
    notAvailable: false,
  });

  const handleHostingStatusChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setHostingStatus({
        cs24: name === "cs24",
        client: name === "client",
        notAvailable: name === "notAvailable",
      });
    } else {
      setHostingStatus({
        ...hostingStatus,
        [name]: false,
      });
    }
  };

  // Domain state
  const [domainStatus, setDomainStatus] = useState({
    cs24: false,
    client: false,
    notAvailable: false,
  });

  const handleDomainStatusChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setDomainStatus({
        cs24: name === "cs24",
        client: name === "client",
        notAvailable: name === "notAvailable",
      });
    } else {
      setDomainStatus({
        ...domainStatus,
        [name]: false,
      });
    }
  };

  // Hosting Credentials state
  const [hostingCredentialsStatus, setHostingCredentialsStatus] = useState({
    available: false,
    notAvailable: false,
  });

  const handleHostingCredentialsStatusChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setHostingCredentialsStatus({
        available: name === "available",
        notAvailable: name === "notAvailable",
      });
    } else {
      setHostingCredentialsStatus({
        ...hostingCredentialsStatus,
        [name]: false,
      });
    }
  };

  // WP-ADMIN Credentials state
  const [wpAdminCredentialsStatus, setWpAdminCredentialsStatus] = useState({
    wpAdminAvailable: false,
    wpAdminNotAvailable: false,
  });

  const handleWpAdminCredentialsStatusChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setWpAdminCredentialsStatus({
        wpAdminAvailable: name === "wpAdminAvailable",
        wpAdminNotAvailable: name === "wpAdminNotAvailable",
      });
    } else {
      setWpAdminCredentialsStatus({
        ...wpAdminCredentialsStatus,
        [name]: false,
      });
    }
  };
  // send data from Backend/////////////////////////////////////////
  const validateForm = () => {
    // Check if all required fields are filled
    if (
      !clientName ||
      !companyName ||
      !clientEmail ||
      !clientPhone ||
      !projectStartDate ||
      !projectAssignTo ||
      !domainName ||
      !hostingName ||
      !technology ||
      !Object.values(projectStatus).includes(true)
    ) {
      alert("Please fill in all required fields.");
      return false;
    }
    if (!Object.values(hostingStatus).includes(true)) {
      alert("Please fill Hosting status.");
      return false;
    }
    if (
      showHostingSection &&
      (!hostingValidity || !hostingDateOfPurchase || !hostingDateOfExpiry)
    ) {
      alert("Please provide all Hosting Validity details.");
      return false;
    }

    if (!Object.values(domainStatus).includes(true)) {
      alert("Please fill domain status.");
      return false;
    }
    if (
      showDomainSection &&
      (!domainValidity || !domainDateOfPurchase || !domainDateOfExpiry)
    ) {
      alert("Please provide all Domain Validity details.");
      return false;
    }

    if (!Object.values(hostingCredentialsStatus).includes(true)) {
      alert("Please fill Hosting Credentials Status.");
      return false;
    }
    // Check if Hosting or WP-Admin credentials are provided when their status is "Available"
    if (
      hostingCredentialsStatus.available &&
      (!hostingCredentialsDetails.username ||
        !hostingCredentialsDetails.password)
    ) {
      alert("Please provide all Hosting Credentials details.");
      return false;
    }

    if (!Object.values(wpAdminCredentialsStatus).includes(true)) {
      alert("Please fill WP Admin Credentials Status.");
      return false;
    }
    if (
      wpAdminCredentialsStatus.wpAdminAvailable &&
      (!wpAdminCredentialsDetails.wpAdminUsername ||
        !wpAdminCredentialsDetails.wpAdminPassword)
    ) {
      alert("Please provide all WP-Admin Credentials details.");
      return false;
    }

    return true;
  };

  const [annualMaintenance, setAnnualMaintenance] = useState({
    available: false,
    notAvailable: false,
  });

  const handleAnnualMaintenanceChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setAnnualMaintenance({
        available: name === "available",
        notAvailable: name === "notAvailable",
      });
    } else {
      setAnnualMaintenance({
        ...annualMaintenance,
        [name]: false,
      });
    }
  };
  // Function to handle start date change and calculate expiry date
  const handleStartDateChange = (event) => {
    const startDate = event.target.value;
    const expiryDate = calculateOneYearExpiry(startDate);
    setAnnualMaintenance((prev) => ({
      ...prev,
      startDate,
      expiryDate,
    }));
  };

  // Helper function to calculate expiry date one year from the start date
  const calculateOneYearExpiry = (startDate) => {
    if (startDate) {
      const startDateObj = new Date(startDate);
      const expiryDateObj = new Date(startDateObj);
      expiryDateObj.setFullYear(startDateObj.getFullYear() + 1);
      return expiryDateObj.toISOString().split("T")[0];
    }
    return "";
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    const formData = {
      clientID: "", // generate a unique ID for the client
      clientName,
      companyName,
      clientEmail,
      clientPhone,
      projectAssigned: projectAssignTo,
      startDate: projectStartDate,
      projectStatus: Object.keys(projectStatus).filter(
        (key) => projectStatus[key]
      ),
      domainName,
      serverName: hostingName,
      technology,
      hostingBy: hostingStatus.cs24
        ? "Purchase By Cs24"
        : hostingStatus.client
          ? "Purchase By Client"
          : "Not Available",
      hostingDateOfPurchase: hostingDateOfPurchase,
      hostingDateOfExpiry: hostingDateOfExpiry,
      hostingValidity: parseInt(hostingValidity),
      domainBy: domainStatus.cs24
        ? "CS24"
        : domainStatus.client
          ? "Client"
          : "Not Available",
      domainPurchaseDate: domainDateOfPurchase,
      domainExpiryDate: domainDateOfExpiry,
      domainValidityYear: parseInt(domainValidity),
      hostingCredentials: hostingCredentialsStatus.available
        ? "Available"
        : "Not Available",
      hostingUserId: hostingCredentialsDetails.username,
      hostingPassword: hostingCredentialsDetails.password,
      wordPressCredentials: wpAdminCredentialsStatus.wpAdminAvailable
        ? "Available"
        : "Not Available",
      wordPressUserId: wpAdminCredentialsDetails.wpAdminUsername,
      wordPressPassword: wpAdminCredentialsDetails.wpAdminPassword,
      annualMaintenance: annualMaintenance.available
        ? "Available"
        : "Not Available",
      annualMaintenanceStartDate: annualMaintenance.startDate,
      annualMaintenanceExpiryDate: annualMaintenance.expiryDate,
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/client-information`,
        formData
      );
      if (response.status === 201) {
        console.log("Form submitted successfully!", response.data);
        alert("Form submitted successfully!");

        // Reset all form fields after successful submission
        setClientName("");
        setcompanyName("");
        setclientEmail("");
        setclientPhone("");
        setProjectStartDate("");
        setProjectAssignTo("");
        setDomainName("");
        sethostingName("");
        settechnology("");
        setProjectStatus({ website: false, socialMedia: false, Closed: false });
        setHostingStatus({ cs24: false, client: false, notAvailable: false });
        setDomainStatus({ cs24: false, client: false, notAvailable: false });
        setHostingCredentialsStatus({ available: false, notAvailable: false });
        setWpAdminCredentialsStatus({
          wpAdminAvailable: false,
          wpAdminNotAvailable: false,
        });
        setDomainValidity("");
        setDomainDateOfPurchase("");
        setDomainDateOfExpiry("");
        sethostingValidity("");
        sethostingDateOfPurchase("");
        sethostingDateOfExpiry("");
        setHostingCredentialsDetails({ username: "", password: "" }); // Reset Hosting Credentials
        setWpAdminCredentialsDetails({
          wpAdminUsername: "",
          wpAdminPassword: "",
        }); // Reset WP-Admin Credentials
        setAnnualMaintenance({
          available: false,
          startDate: "",
          expiryDate: "",
        }); // Reset Annual Maintenance fields
      } else {
        console.error("Unexpected response status:", response.status);
        alert("Form submission failed!");
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        alert(
          `Form submission failed: ${error.response.data.message || error.response.data
          }`
        );
      } else {
        alert("An error occurred while submitting the form.");
      }
    }
  };

  // Control visibility of sections based on checkbox selection
  const showHostingSection = hostingStatus.cs24;
  const showDomainSection = domainStatus.cs24;


  // Hosting Credentials Details
  const [hostingCredentialsDetails, setHostingCredentialsDetails] = useState({
    username: "",
    password: "",
  });

  // WP-Admin Credentials Details
  const [wpAdminCredentialsDetails, setWpAdminCredentialsDetails] = useState({
    wpAdminUsername: "",
    wpAdminPassword: "",
  });

  return (
    <div className="min-w-full mt-3  h-472 p-6 md:mt-4  shadow-md   bg-white rounded-3xl  md:block" style={{ maxWidth: '900px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 20px ', borderRadius: '30px' }}>
      <h2 className="md:text-2xl text-[17px] font-bold text-gray-700 mb-2">Add New Client</h2>
      {/* Horizontal line */}
      <hr className="border-t border-gray-300 mx-[-24px] mb-6" />



      <form className="grid ml-4 grid-cols-1 gap-6" onSubmit={handleSubmit}>
        <div
            className="p-6 rounded-md md:custom-border md:mr-0 mr-6"
          style={{
            borderTop: '1px dashed #ccc', // Light gray
            borderRight: '1px dashed #ccc',
            borderBottom: '1px dashed #ccc',
            borderLeft: '1px dashed #ccc',
          }}
            
        >        <div className="mb-6">
            <label className="text-gray-700 font-bold ">Project Status </label>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3">

              <div className="flex flex-col mt-5 sm:flex-row gap-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="Website"
                    checked={projectStatus.Website}
                    onChange={handleProjectStatusChange}
                    className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                  />
                  <label className="text-gray-600">Website</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="SocialMedia"
                    checked={projectStatus.SocialMedia}
                    onChange={handleProjectStatusChange}
                    className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                  />
                  <label className="text-gray-600">Social Media</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="Closed"
                    checked={projectStatus.Closed}
                    onChange={handleProjectStatusChange}
                    className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                  />
                  <label className="text-gray-600">Closed</label>
                </div>
              </div>

            </div>
          </div>
          <p className="text-gray-700 font-bold mb-4">Client Personal Info </p>

          {/* Using grid to arrange two fields per row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Client Name</label>
              <input
                required
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder="Enter Name" // Placeholder added
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Company Name</label>
              <input
                required
                type="text"
                value={companyName}
                onChange={(e) => setcompanyName(e.target.value)}
                placeholder="Enter Company" // Placeholder added
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Client Email</label>
              <input
                required
                type="email"
                value={clientEmail}
                onChange={(e) => setclientEmail(e.target.value)}
                placeholder="Enter Email" // Placeholder added
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Client Phone</label>
              <input
                required
                type="tel"
                value={clientPhone}
                onChange={(e) => setclientPhone(e.target.value)}
                placeholder="Enter Phone" // Placeholder added
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Project Start Date</label>
              <input
                required
                type="date"
                value={projectStartDate}
                onChange={(e) => setProjectStartDate(e.target.value)}
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Project Assign To</label>
              <input
                required
                type="text"
                value={projectAssignTo}
                onChange={(e) => setProjectAssignTo(e.target.value)}
                placeholder="Enter Assign" // Placeholder added
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Domain Name</label>
              <input
                required
                type="text"
                value={domainName}
                onChange={(e) => setDomainName(e.target.value)}
                placeholder="Enter Domain " // Placeholder added
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Hosting Name</label>
              <input
                type="text"
                value={hostingName}
                onChange={(e) => sethostingName(e.target.value)}
                placeholder="Enter Hosting" // Placeholder added
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Technology</label>
              <input
                type="text"
                value={technology}
                onChange={(e) => settechnology(e.target.value)}
                placeholder="Enter Technology" // Placeholder added
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

        </div>


        <div className="sm:col-span-2  mr-6"> {/* Adjust the margin-right value as needed */}

          <div
            className="p-6 rounded-md"
            style={{
              borderTop: '1px dashed #ccc', // Light gray
              borderRight: '1px dashed #ccc',
              borderBottom: '1px dashed #ccc',
              borderLeft: '1px dashed #ccc',
            }}

          >
            <p className="text-gray-700 font-bold mb-2">Hosting </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="cs24"
                  checked={hostingStatus.cs24}
                  onChange={handleHostingStatusChange}
                  className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                />
                <label className="text-gray-500">PUR by CS24</label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="client"
                  checked={hostingStatus.client}
                  onChange={handleHostingStatusChange}
                  className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                />
                <label className="text-gray-500">PUR by Client</label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="notAvailable"
                  checked={hostingStatus.notAvailable}
                  onChange={handleHostingStatusChange}
                  className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                />
                <label className="text-gray-500">Not Available</label>
              </div>
            </div>



            {showHostingSection && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-500 mb-1">Hosting Validity</label>
                    <select
                      value={hostingValidity}
                      onChange={handlehostingValidityChange}
                      className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-1 focus:ring-gray-300 focus:border-gray-300" // Updated focus classes
                    >
                      <option value="">Select validity</option>
                      <option value="1">1 year</option>
                      <option value="2">2 years</option>
                      <option value="3">3 years</option>
                      <option value="4">4 years</option>
                      <option value="5">5 years</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-500 mb-1">Date of Purchase</label>
                    <input
                      type="date"
                      value={hostingDateOfPurchase}
                      onChange={handlehostingDateOfPurchaseChange}
                      className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-1 focus:ring-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-outline-gray" // Updated focus classes
                    />
                  </div>

                  <div className="md:col-span-2">
                    <label className="block text-sm font-medium text-gray-500 mb-1">Date of Expiry</label>
                    <input
                      type="date"
                      value={hostingDateOfExpiry}
                      readOnly
                      className="w-[442px] p-2 border border-gray-300 rounded-md shadow-sm focus:ring-1 focus:ring-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-outline-gray" // Updated focus classes
                    />
                  </div>



                </div>
              </>


            )}
            <div className="md:col-span-2 ">
              <div className="mt-6">
                <p className="text-gray-700 font-bold mb-2">Hosting Credentials</p>
                <div className="flex flex-col sm:flex-row gap-4 mb-4">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="available"
                      checked={hostingCredentialsStatus.available}
                      onChange={handleHostingCredentialsStatusChange}
                      className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                    />
                    <label className="text-gray-600">Available</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="notAvailable"
                      checked={hostingCredentialsStatus.notAvailable}
                      onChange={handleHostingCredentialsStatusChange}
                      className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                    />
                    <label className="text-gray-600">Not Available</label>
                  </div>
                </div>


                {hostingCredentialsStatus.available && (
                  <>
                    <div className="mb-4 flex space-x-4"> {/* Add flex and spacing */}
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Hosting Username
                        </label>
                        <input
                          type="text"
                          value={hostingCredentialsDetails.username}
                           placeholder="Enter Username"
                          onChange={(e) =>
                            setHostingCredentialsDetails({
                              ...hostingCredentialsDetails,
                              username: e.target.value,
                            })
                          }
                          className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                        />
                      </div>
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Hosting Password
                        </label>
                        <div className="relative">
                          <input
                            type={showPassword ? 'text' : 'password'} // Toggle between text and password
                            value={hostingCredentialsDetails.password}
                             placeholder="Enter Password"
                            onChange={(e) =>
                              setHostingCredentialsDetails({
                                ...hostingCredentialsDetails,
                                password: e.target.value,
                              })
                            }
                            className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 pr-10" // Add padding right for the icon
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 flex items-center pr-3"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <EyeSlashIcon
                                className="h-5 w-5 text-gray-500"
                                style={{ animation: 'none' }}// Override any unwanted filters
                                aria-hidden="true"
                              />
                            ) : (
                              <EyeIcon
                                className="h-5 w-5 text-gray-500"
                                style={{ animation: 'none' }}// Override any unwanted filters
                                aria-hidden="true"
                              />
                            )}
                          </button>

                        </div>
                      </div>
                    </div>
                  </>

                )}
              </div>
            </div>
          </div>
        </div>

        <div className="sm:col-span-2 ">
          <div
            className="p-6 rounded-md mr-6"
            style={{
              borderTop: '1px dashed #ccc', // Light gray
              borderRight: '1px dashed #ccc',
              borderBottom: '1px dashed #ccc',
              borderLeft: '1px dashed #ccc',
            }}

          >
            <p className="text-gray-700 font-bold mb-2">Domain </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="cs24"
                  checked={domainStatus.cs24}
                  onChange={handleDomainStatusChange}
                  className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                />
                <label className="text-gray-600">PUR by CS24</label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="client"
                  checked={domainStatus.client}
                  onChange={handleDomainStatusChange}
                  className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                />
                <label className="text-gray-600">PUR by Client</label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="notAvailable"
                  checked={domainStatus.notAvailable}
                  onChange={handleDomainStatusChange}
                  className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                />
                <label className="text-gray-600">Not Available</label>
              </div>
            </div>


            {showDomainSection && (
              <>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Domain Validity</label>
                    <select
                      value={domainValidity}
                      onChange={handleDomainValidityChange}
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                    >
                      <option value="">Select validity</option>
                      <option value="1">1 year</option>
                      <option value="2">2 years</option>
                      <option value="3">3 years</option>
                      <option value="4">4 years</option>
                      <option value="5">5 years</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Date of Purchase</label>
                    <input
                      type="date"
                      value={domainDateOfPurchase}
                      onChange={handleDomainDateOfPurchaseChange}
                      className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-1 focus:ring-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-outline-gray"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Date of Expiry</label>
                  <input
                    type="date"
                    value={domainDateOfExpiry}
                    readOnly
                    className="w-[442px] p-2 border border-gray-300 rounded-md shadow-sm focus:ring-1 focus:ring-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-outline-gray"
                  />
                </div>
              </>
            )}


          </div>
        </div>

        <div className="md:col-span-2 mb-6">
          <div
            className="p-6 rounded-md mr-6"
            style={{
              borderTop: '1px dashed #ccc',
              borderRight: '1px dashed #ccc',
              borderBottom: '1px dashed #ccc',
              borderLeft: '1px dashed #ccc',
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-gray-700 font-bold mb-4">WordPress Credentials</p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="wpAdminAvailable"
                      checked={wpAdminCredentialsStatus.wpAdminAvailable}
                      onChange={handleWpAdminCredentialsStatusChange}
                      className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                    />
                    <label className="text-gray-600">Available</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="wpAdminNotAvailable"
                      checked={wpAdminCredentialsStatus.wpAdminNotAvailable}
                      onChange={handleWpAdminCredentialsStatusChange}
                      className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                    />
                    <label className="text-gray-600">Not Available</label>
                  </div>
                </div>

                {wpAdminCredentialsStatus.wpAdminAvailable && (
                  <>
                    <div>
                      <label className="block text-sm  mt-4 font-medium text-gray-700 mb-2 ">
                        WordPress Username
                      </label>
                      <input
                        type="text"
                        value={wpAdminCredentialsDetails.wpAdminUsername}
                         placeholder="Enter Username"
                        onChange={(e) =>
                          setWpAdminCredentialsDetails({
                            ...wpAdminCredentialsDetails,
                            wpAdminUsername: e.target.value,
                          })
                        }
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      />
                    </div>
                    <div>
                      <div>
                        <label className="block text-sm mt-4 font-medium text-gray-700 mb-2 ">
                          WordPress Password
                        </label>
                        <div className="relative">
                          <input
                            type={showPassword ? 'text' : 'password'} // Toggle between text and password
                            value={wpAdminCredentialsDetails.wpAdminPassword}
                             placeholder="Enter Password"
                            onChange={(e) =>
                              setWpAdminCredentialsDetails({
                                ...wpAdminCredentialsDetails,
                                wpAdminPassword: e.target.value,
                              })
                            }
                            className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                          />
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)} // Toggle the state
                            className="absolute inset-y-0 right-0 flex items-center pr-3"
                          >
                            {showPassword ? (
                              <EyeIcon className="h-5 w-5 text-gray-500" style={{ animation: 'none' }} />

                            ) : (
                              <EyeIcon className="h-5 w-5 text-gray-500" style={{ animation: 'none' }} />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div>
                <p className="text-gray-700 font-bold mb-4">Annual Maintenance Contract</p>
                <div className="flex flex-col sm:flex-row gap-4 mb-2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="available"
                      checked={annualMaintenance.available}
                      onChange={handleAnnualMaintenanceChange}
                      className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                    />
                    <label className="text-gray-600">Available</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="notAvailable"
                      checked={annualMaintenance.notAvailable}
                      onChange={handleAnnualMaintenanceChange}
                      className="mr-2 h-5 w-5 text-indigo-600 accent-[#006CB5] border-gray-300 rounded focus:outline-none"
                    />
                    <label className="text-gray-600">Not Available</label>
                  </div>
                </div>

                {annualMaintenance.available && (
                  <>
                    <div className="mb-1">
                      <label className="block text-sm mt-4 font-medium text-gray-700 mb-2">
                        Start Date
                      </label>
                      <input
                        type="date"
                        value={annualMaintenance.startDate}
                        onChange={handleStartDateChange}
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      />
                    </div>
                    <div className="">
                      <label className="block mt-4 text-sm font-medium text-gray-700 mb-2">
                        Expiry Date
                      </label>
                      <input
                        type="text"
                        value={annualMaintenance.expiryDate}
                        readOnly
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      />
                    </div>
                  </>
                )}

              </div>
            </div>
          </div>
          <div className="md:col-span-2 pt-6 mt-2 flex mr-6 justify-end">
            <button
              type="submit"
              className="bg-[#006CB5] text-white font-semibold py-2 px-8 rounded-[8px] hover:bg-blue-700 transition duration-300 shadow-lg w-full sm:w-auto "
            >
              Submit
            </button>
          </div>
        </div>


        {/* Annual Maintenance Section */}


      </form>
    </div>

  );
};

export default ClientInformationForm;
