import React, { useState, useEffect } from 'react';
import API_BASE_URL from '../../ApiConfig/ApiConfig';

const Dashboard = () => {
  const [clientData, setClientData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentEventPage, setCurrentEventPage] = useState(1); // New state for event pagination
  const [activeSection, setActiveSection] = useState('activities');
  const entriesPerPage = 4; // Number of entries per page for clients
  const eventsPerPage = 4; // Number of entries per page for events

  // Fetch Client Data
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/Expiry/fetch`);
        if (response.ok) {
          const data = await response.json();
          setClientData(data);
        } else {
          console.error('Failed to fetch client data', await response.text());
        }
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    fetchClientData();
  }, []);

  // Fetch Event Data
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/events/fetch-upcoming`);
        if (response.ok) {
          const data = await response.json();
          setEventData(data.events);
        } else {
          console.error('Failed to fetch event data', await response.text());
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchEventData();
  }, []);

  // Function to format event date
  const formatEventDate = (date) => {
    if (!date) return 'N/A';

    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options).replace(/,/, ''); // Remove comma for cleaner formatting
  };

  // Function to calculate expiry message
  const getExpiryMessage = (expiryDate) => {
    if (!expiryDate) return 'Safe';

    const expiry = new Date(expiryDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    expiry.setHours(0, 0, 0, 0);

    const timeDiff = expiry - today;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysLeft < 0) {
      return 'Expired';
    } else if (daysLeft <= 30) {
      return `${daysLeft} Days`;
    } else {
      return 'Safe';
    }
  };

  // Function to determine the color based on expiry message
  const getExpiryMessageColor = (expiryDate) => {
    const message = getExpiryMessage(expiryDate);
    if (message.includes('Expired')) {
      return 'bg-red-200 text-red-600 border border-red-600 rounded-md';
    }
    if (message.includes('Days')) {
      return 'bg-orange-200 text-orange-600 border border-orange-600 rounded-md';
    }
    if (message === 'Safe') {
      return 'bg-green-200 text-green-600 border border-green-600 rounded-md';
    }
    return 'bg-gray-200 text-gray-600 border border-gray-600 rounded-md';
  };

  // Client Pagination Logic
  // Client Pagination Logic (filter clients whose domain and hosting are not "Safe" or "No expiry date")
  const filteredClientData = clientData.filter(client => {
    const domainStatus = getExpiryMessage(client.domainExpiryDate);
    const hostingStatus = getExpiryMessage(client.hostingDateOfExpiry);

    return !(domainStatus === 'Safe' || domainStatus === 'No expiry date') ||
      !(hostingStatus === 'Safe' || hostingStatus === 'No expiry date');
  });


  const totalEntries = filteredClientData.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);
  const startIndex = (currentPage - 1) * entriesPerPage;
  const currentEntries = filteredClientData.slice(startIndex, startIndex + entriesPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const totalEventEntries = eventData.length;
  const totalEventPages = Math.ceil(totalEventEntries / eventsPerPage);
  const startEventIndex = (currentEventPage - 1) * eventsPerPage;

  // Sort events by days left (ascending order)
  const sortedEvents = eventData.sort((a, b) => {
    const daysLeftA = parseInt(getExpiryMessage(a.eventDate), 10) || Infinity; // Use Infinity for 'No expiry date'
    const daysLeftB = parseInt(getExpiryMessage(b.eventDate), 10) || Infinity;

    return daysLeftA - daysLeftB;
  });

  const currentEvents = sortedEvents.slice(startEventIndex, startEventIndex + eventsPerPage);

  const handleEventPageChange = (page) => {
    if (page >= 1 && page <= totalEventPages) {
      setCurrentEventPage(page);
    }
  };
  return (
    <div className="">

      {/* Navbar Content */}


      <div className="fixed top-16 left-0 right-0 bg-white shadow-md z-10 p-4 md:hidden mb-14 flex justify-between">
        <div className="flex flex-col items-center flex-1">
          <button
            onClick={() => setActiveSection('activities')}
            className={`flex-1 font-semibold text-black text-xs py-1 rounded transition-all duration-300 border-b-2 ${activeSection === 'activities' ? 'border-[#006CB5] font-bold' : 'border-transparent'}`}
          >
            Upcoming Events
          </button>
        </div>
        <div className="flex flex-col items-center flex-1">
          <button
            onClick={() => setActiveSection('domain')}
            className={`flex-1 font-semibold text-black text-xs py-1 rounded transition-all duration-300 border-b-2 ${activeSection === 'domain' ? 'border-[#006CB5] font-bold' : 'border-transparent'}`}
          >
           Client Overview
          </button>
        </div>

      </div>
      {/* Event Schedule Section with Pagination */}

      <section
        className={`min-w-full md:mt-4 mt-14  h-472  p-4 bg-white md:rounded-3xl rounded-xl shadow-2xl ${activeSection === 'activities' ? '' : 'hidden'} md:block`}
        style={{ maxWidth: '900px', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)' }}
      >
        <h1 className="text-xl font-bold mb-6 hidden md:block">Upcoming Events</h1>

        {/* For larger screens, display table */}
        <div className="hidden md:block">
          <table className="min-w-full border border-gray-300 rounded-lg h-[290px] overflow-hidden">
            <thead>
              <tr className="w-full  text-sm leading-normal bg-gray-200 text-gray-500">
                <th className="py-3 px-6  text-sm font-semibold text-left">Event Name</th>
                <th className="py-3 px-6 text-sm font-semibold text-left">Event Date</th>
                <th className="py-3 px-6 text-sm font-semibold text-left">Country</th>
                <th className="py-3 px-6 text-sm font-semibold text-left">Work</th>
                <th className="py-3 px-6 text-sm font-semibold text-left">Days Left</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-bold">
              {currentEvents.length > 0 ? (
                currentEvents.map((event, index) => {
                  const daysLeft = getExpiryMessage(event.eventDate);
                  const daysLeftNum = parseInt(daysLeft, 10); // Extract number of days left for comparison
                  const isRed = daysLeftNum <= 5; // Determine if it should be red

                  return (
                    <React.Fragment key={event._id}>
                      <tr className="hover:bg-gray-100 border-b border-gray-300">
                        <td className="py-3 px-6">{event.eventName}</td>
                        <td className="py-3 px-6">{formatEventDate(event.eventDate)}</td>
                        <td className="py-3 px-6">{event.country.join(', ')}</td>
                        <td className="py-3 px-6">{event.work.join(', ')}</td>
                        <td className="py-3 px-6">
                          <span
                            className={`${isRed ? 'text-red-600' : 'text-green-600'}`}
                            style={{
                              display: 'inline-block',
                              width: '100px',
                              textAlign: 'left',
                              fontFamily: 'Poppins, sans-serif',
                              fontSize: '14px',
                              fontWeight: '600',
                              lineHeight: '21px',
                              letterSpacing: '-0.01em',
                            }}
                          >
                            {daysLeft}
                          </span>
                        </td>
                      </tr>
                      {index === currentEvents.length - 1 && (
                        <tr>
                          <td colSpan="5" className="border-b border-gray-400"></td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="py-3 px-6 text-center text-gray-500 border-gray-300">
                    No events available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>



        {/* For mobile screens, display events in separate containers */}
        <div className="md:hidden">
          {currentEvents.length > 0 ? (
            currentEvents.map((event) => {
              const daysLeft = getExpiryMessage(event.eventDate);
              const daysLeftNum = parseInt(daysLeft, 10); // Extract number of days left for comparison
              const isRed = daysLeftNum <= 5; // Determine if it should be red

              return (
                <div key={event._id} className=" rounded-lg p-3 mb-4 border-dashed border-b border-gray-300">
                  {/* Post Name */}
                  <div className="flex justify-between items-center mb-3">
                    <span className="text-[#AAAAAA] font-semibold text-sm">Post Name</span>
                    <span className="font-semibold text-sm">{event.eventName}</span>
                  </div>

                  {/* Date */}
                  <div className="flex justify-between items-center mb-3">
                    <span className="text-[#AAAAAA] font-semibold text-sm">Date</span>
                    <span className="font-semibold text-sm">{formatEventDate(event.eventDate)}</span>
                  </div>

                  {/* Country */}
                  <div className="flex justify-between items-center mb-3">
                    <span className="text-[#AAAAAA] font-semibold text-sm">Country</span>
                    <span className="font-semibold text-sm">{event.country.join(', ')}</span>
                  </div>

                  {/* Work */}
                  <div className="flex justify-between items-center mb-3">
                    <span className="text-[#AAAAAA] font-semibold text-sm">Work</span>
                    <span className="font-semibold text-sm">{event.work.join(', ')}</span>
                  </div>

                  {/* Days Left */}
                  <div className="flex justify-between items-center">
                    <span className="text-[#AAAAAA] font-semibold text-sm">Days Left</span>
                    <span
                      className={`${isRed ? 'text-red-600' : 'text-green-600'}`} // Removed bg and border classes
                      style={{
                        display: 'inline-block',
                        width: '100px',
                        textAlign: 'end',
                        fontFamily: 'Poppins, sans-serif', // Use Poppins font
                        fontSize: '14px', // Set font size
                        fontWeight: '600', // Slightly bolder
                        lineHeight: '21px', // Set line height
                        letterSpacing: '-0.01em', // Set letter spacing
                      }}
                    >
                      {daysLeft}
                    </span>
                  </div>

                </div>
              );
            })
          ) : (
            <div className="text-center text-gray-500">No events available.</div>
          )}
        </div>

        {/* Pagination for Events */}
        <div className="flex sm:justify-between justify-center sm:items-center items-center mt-4">
          {totalEventEntries > 0 && (
            <>
              <div className="text-xs ml-2 text-gray-600 font-bold hidden sm:block">
                <p>Total Pages: {totalEventPages}</p>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => handleEventPageChange(currentEventPage - 1)}
                  disabled={currentEventPage === 1}
                  className={`mr-2 px-2 py-1 bg-gray-300 rounded-full text-xs hover:bg-gray-400 transition duration-200 
            ${currentEventPage === 1 ? 'opacity-50 cursor-not-allowed' : ''} font-bold`}
                >
                  &lt; Prev
                </button>
                {Array.from({ length: totalEventPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handleEventPageChange(index + 1)}
                    className={`mx-1 px-2 py-1 rounded-full text-xs transition duration-200 
              ${currentEventPage === index + 1 ? 'bg-[#1171bb] text-white' : 'bg-gray-300 hover:bg-gray-400'} font-bold`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => handleEventPageChange(currentEventPage + 1)}
                  disabled={currentEventPage === totalEventPages}
                  className={`ml-2 px-2 py-1 bg-gray-300 rounded-full text-xs hover:bg-gray-400 transition duration-200 
            ${currentEventPage === totalEventPages ? 'opacity-50 cursor-not-allowed' : ''} font-bold`}
                >
                  Next &gt;
                </button>
              </div>
            </>
          )}
        </div>



      </section>


      <section
        className={`min-w-full ${activeSection === 'activities' ? 'hidden md:block' : 'block'} p-4 bg-[#ffffff] md:rounded-3xl rounded-xl shadow-2xl md:mt-10 mt-14`}
        style={{ maxWidth: '900px', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)' }}
      >
        <h1 className="text-xl font-bold mb-6 hidden md:block">Client Overview</h1>
        {/* Container for mobile responsiveness */}
        <div className="hidden md:block">
          <table className="min-w-full border border-gray-300 rounded-lg overflow-hidden">
            <thead>
              <tr className="w-full  text-sm leading-normal bg-gray-200 text-gray-500">
                <th className="py-3 px-6 text-left border-b text-sm font-semibold border-gray-300 border-r">Company Name</th>
                <th className="py-3 px-6 text-left border-b text-sm font-semibold border-gray-300">Domain</th>
                <th className="py-3 px-6 text-left border-b text-sm font-semibold border-gray-300 border-r">Status</th>
                <th className="py-3 px-6 text-left border-b text-sm font-semibold border-gray-300">Hosting</th>
                <th className="py-3 px-6 text-left border-b text-sm font-semibold border-gray-300">Status</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-bold">
              {currentEntries.length > 0 ? (
                currentEntries.map(client => (
                  <tr key={client.clientID} className="hover:bg-gray-100">
                    <td className="py-3 px-6 border-r border-gray-300 font-bold">{client.companyName}</td>
                    <td className="py-3 px-6 border-gray-300 font-bold">{client.domainName}</td>
                    <td className="py-3 px-6 border-r border-gray-300 font-bold">
                      <span className={`py-1 px-4 text-xs font-bold ${getExpiryMessageColor(client.domainExpiryDate)}`} style={{ display: 'inline-block', width: '100px', textAlign: 'center' }}>
                        {getExpiryMessage(client.domainExpiryDate)}
                      </span>
                    </td>
                    <td className="py-3 px-6 border-gray-300 font-bold">{client.serverName}</td>
                    <td className="py-3 px-6 font-bold">
                      <span className={`py-1 px-4 text-xs font-bold ${getExpiryMessageColor(client.hostingDateOfExpiry)}`} style={{ display: 'inline-block', width: '100px', textAlign: 'center' }}>
                        {getExpiryMessage(client.hostingDateOfExpiry)}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="py-3 px-6 mt-7 text-center text-gray-500 border-gray-300">
                    No clients available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Mobile layout */}
        <div className="md:hidden ">
          {currentEntries.length > 0 ? (
            currentEntries.map(client => (
              <div
                key={client.clientID}
                className="rounded-lg p-3 mb-4 border-dashed border-b border-gray-300"
              >
                {/* Company Name */}
                <div className="flex justify-between items-center mb-2">
                  <span className="text-[#AAAAAA] font-semibold text-sm">Company Name</span>
                  <span className="font-semibold text-sm">{client.companyName}</span>
                </div>

                {/* Domain */}
                <div className="flex justify-between items-center mb-2">
                  <span className="text-[#AAAAAA] font-semibold text-sm">Domain</span>
                  <span className="font-semibold text-sm">{client.domainName}</span>
                </div>

                {/* Domain Status */}
                <div className="flex justify-between items-center mb-2">
                  <span className="text-[#AAAAAA] font-semibold text-sm">Domain Status</span>
                  <span
                    className={`font-semibold py-1 px-4 text-xs rounded-full ${getExpiryMessageColor(client.domainExpiryDate)}`}
                  >
                    {getExpiryMessage(client.domainExpiryDate)}
                  </span>
                </div>

                {/* Hosting */}
                <div className="flex justify-between items-center mb-3">
                  <span className="text-[#AAAAAA] font-semibold text-sm">Hosting</span>
                  <span className="font-semibold  text-sm">{client.serverName}</span>
                </div>

                {/* Hosting Status */}
                <div className="flex justify-between items-center mb-3">
                  <span className="text-[#AAAAAA] font-semibold text-sm">Hosting Status</span>
                  <span
                    className={`font-semibold py-1 px-4 text-xs rounded-full ${getExpiryMessageColor(client.hostingDateOfExpiry)}`}
                  >
                    {getExpiryMessage(client.hostingDateOfExpiry)}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="mt-7 text-center text-gray-500">No clients available.</div>
          )}
        </div>

        <div className="flex sm:justify-between justify-center items-center mt-4">
          {totalEntries > 0 && (
            <>
              <div className="text-xs ml-2 text-gray-600 font-bold hidden sm:block">
                <p>Total Pages: {totalPages}</p>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`mr-2 px-2 py-1 bg-gray-300 rounded-full text-xs hover:bg-gray-400 transition duration-200 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''} font-bold`}
                >
                  &lt; Prev
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={`mx-1 px-2 py-1 rounded-full text-xs transition duration-200  ${currentPage === index + 1
                      ? 'bg-[#1171bb] text-white'
                      : 'bg-gray-300 hover:bg-gray-400'
                      } font-bold`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`ml-2 px-2 py-1 bg-gray-300 rounded-full text-xs hover:bg-gray-400 transition duration-200   ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''} font-bold`}
                >
                  Next &gt;
                </button>
              </div>
            </>
          )}
        </div>

      </section>





    </div>
  );
};

export default Dashboard;
