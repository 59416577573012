// src/Components/ProtectedRoute/AdminPrivateRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from 'js-cookie'; // Import the js-cookie library

const AdminPrivateRoute = () => {
  const token = Cookies.get("Admintoken"); 
  
  // If token does not exist, redirect to login page
  if (!token) {
    return <Navigate to="/admin/login" />;
  }

  return <Outlet />;
};

export default AdminPrivateRoute;