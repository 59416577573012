import React, { useState, useEffect, useCallback } from 'react';
import AddUserForm from './AddUserForm';
import UserList from './UserList';
import logo from '../../assets/CS24_new_logo_final_white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUsers, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import defaultImage from '../../assets/default icon.png';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('addUser');
  const [profileData, setProfileData] = useState({
    name: '',
    designation: '',
    profileImage: '',
  });
  const [imageExists, setImageExists] = useState(true);

  const fetchProfileData = useCallback(async (username) => {
    try {
      const response = await fetch(`${API_BASE_URL}/profileDetails/profile/username/${username}`);
      if (response.ok) {
        const data = await response.json();
        const { name, designation, profileImage } = data;
        setProfileData({ name, designation, profileImage });
      } else {
        console.error('Failed to fetch profile data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;
      fetchProfileData(username);
    }
  }, [fetchProfileData]);

  useEffect(() => {
    if (profileData.profileImage) {
      const img = new Image();
      img.src = `${API_BASE_URL}/${profileData.profileImage}`;
      img.onload = () => setImageExists(true);
      img.onerror = () => setImageExists(false);
    }
  }, [profileData.profileImage]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'addUser':
        return <AddUserForm />;
      case 'viewUsers':
        return <UserList />;
      default:
        return <AddUserForm />;
    }
  };

  const handleLogout = () => {
    Cookies.remove('token');
    window.location.href = '/login'; // Redirect to the login page after logging out
  };

  return (
    <div className="flex bg-[#FAFBFF] mx-h-screen">
      {/* Sidebar */}
      <aside className="fixed top-0 left-0 w-64 bg-[#006CB5] text-white h-full flex flex-col py-6 px-4">
        <div className="flex items-center justify-center mb-8">
          <img src={logo} alt="Logo" className="h-12 w-auto" />
        </div>

        <nav className="flex flex-col space-y-4">
          <button
            onClick={() => setActiveTab('addUser')}
            className={`flex items-center py-2 px-4 text-left font-bold transition duration-200 w-full border-l-4 ${activeTab === 'addUser' ? 'border-white text-white' : 'border-transparent text-[#1FAAE2]'} hover:text-white`}
          >
            <FontAwesomeIcon
              icon={faUserPlus}
              className={`mr-2 ml-4 ${activeTab === 'addUser' ? 'text-white' : 'text-[#1FAAE2]'}`}
            />
            Add User
          </button>
          <button
            onClick={() => setActiveTab('viewUsers')}
            className={`flex items-center py-2 px-4 text-left font-bold transition duration-200 w-full border-l-4 ${activeTab === 'viewUsers' ? 'border-white text-white' : 'border-transparent text-[#1FAAE2]'} hover:text-white`}
          >
            <FontAwesomeIcon
              icon={faUsers}
              className={`mr-2 ml-4 ${activeTab === 'viewUsers' ? 'text-white' : 'text-[#1FAAE2]'}`}
            />
            View Users
          </button>
        </nav>


        {/* Logout Button */}
        <div className="mt-auto">
          <button
            onClick={handleLogout}
            className="flex items-center py-2 text-[#1FAAE2] px-4 text-left rounded font-bold transition duration-200 w-full hover:text-white"
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 ml-4" />
            Logout
          </button>
        </div>
      </aside>


      {/* Main Content Wrapper with offset for sidebar */}
      <div className="flex-grow flex flex-col ml-64">
        {/* Top Navigation Bar */}
        <header className="fixed top-0 left-64 right-0 shadow-md lg:px-6 bg-[#006CB5] h-16 z-20 lg:h-20 lg:bg-white flex items-center justify-between px-6">
          <div></div>

          {/* Profile Section on the Right */}
          <div className="flex items-center space-x-4">
            <img
              src={
                imageExists
                  ? `${API_BASE_URL}/${profileData.profileImage}`
                  : defaultImage
              }
              alt="Profile"
              className="h-10 w-10 rounded-full border-2 border-white lg:border-[#006CB5] object-cover shadow-md"
            />
            <div className="text-left">
              <h2 className="text-[#006CB5] font-semibold">
                {profileData.name || 'Loading...'}
              </h2>
              <p className="text-gray-400 text-sm">
                {profileData.designation || 'Loading...'}
              </p>
            </div>
          </div>
        </header>

        {/* Dashboard Content with space for fixed header */}
        <main className="flex-grow bg-white p-8 mt-16 overflow-auto">
          {renderTabContent()}
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
