import React, { useState, useEffect } from "react";
import axios from "axios";
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
const EditClientInformation = ({ clientData, onClose }) => {
  const initialHostingStatus = {
    cs24: clientData.hostingBy === "Purchase By Cs24",
    client: clientData.hostingBy === "Purchase By Client",
    notAvailable: clientData.hostingBy === "Not Available",
  };
  const initialDomainStatus = {
    cs24: clientData.domainBy === "Purchase By Cs24",
    client: clientData.domainBy === "Purchase By Client",
    notAvailable: clientData.domainBy === "Not Available",
  };

  const initialHostingCredentialsStatus = clientData.hostingCredentials;
  const initialWordPressCredentialsStatus = clientData.wordPressCredentials;
  const initialannualMaintenanceStatus = clientData.annualMaintenance;
  const [formData, setFormData] = useState({
    clientName: clientData.clientName || "",
    companyName: clientData.companyName || "",
    clientEmail: clientData.clientEmail || "",
    clientPhone: clientData.clientPhone || "",
    startDate: clientData.startDate
      ? new Date(clientData.startDate).toISOString().split("T")[0]
      : "",
    projectAssigned: clientData.projectAssigned || "",
    domainName: clientData.domainName || "",
    hostingName: clientData.serverName || "",
    technology: clientData.technology || "",
    projectStatus: clientData.projectStatus || [], // Ensure this is an array if that’s what your schema expects
    hostingBy: initialHostingStatus,
    domainBy: initialDomainStatus,

    hostingCredentials: initialHostingCredentialsStatus, // Adjust this if needed
    wordPressCredentials: initialWordPressCredentialsStatus, // Adjust this if needed

    annualMaintenance: initialannualMaintenanceStatus,

    annualMaintenanceStartDate: clientData.annualMaintenanceStartDate
      ? new Date(clientData.annualMaintenanceStartDate)
        .toISOString()
        .split("T")[0]
      : "",

    annualMaintenanceExpiryDate: clientData.annualMaintenanceExpiryDate
      ? new Date(clientData.annualMaintenanceExpiryDate)
        .toISOString()
        .split("T")[0]
      : "",
    domainValidityYear: clientData.domainValidityYear || "",
    domainPurchaseDate: clientData.domainPurchaseDate
      ? new Date(clientData.domainPurchaseDate).toISOString().split("T")[0]
      : "",
    domainExpiryDate: clientData.domainExpiryDate
      ? new Date(clientData.domainExpiryDate).toISOString().split("T")[0]
      : "",
    hostingValidity: clientData.hostingValidity || "",
    hostingDateOfPurchase: clientData.hostingDateOfPurchase
      ? new Date(clientData.hostingDateOfPurchase).toISOString().split("T")[0]
      : "",
    hostingDateOfExpiry: clientData.hostingDateOfExpiry
      ? new Date(clientData.hostingDateOfExpiry).toISOString().split("T")[0]
      : "",

    hostingUserId: clientData.hostingUserId || "",
    hostingPassword: clientData.hostingPassword || "",

    wordPressUserId: clientData.wordPressUserId || "",

    wordPressPassword: clientData.wordPressPassword || "",
  });
  useEffect(() => {
    if (formData.domainValidityYear && formData.domainPurchaseDate) {
      calculateExpiryDate(
        formData.domainValidityYear,
        formData.domainPurchaseDate,

        "domainDateOfExpiry"
      );
    }
  }, [formData.domainValidityYear, formData.domainPurchaseDate]);

  useEffect(() => {
    if (formData.hostingValidity && formData.hostingDateOfPurchase) {
      calculateExpiryDate(
        formData.hostingValidity,
        formData.hostingDateOfPurchase,

        "hostingDateOfExpiry"
      );
    }
  }, [formData.hostingValidity, formData.hostingDateOfPurchase]);

  // Calculate the expiry date when the start date changes
  useEffect(() => {
    if (formData.annualMaintenanceStartDate) {
      calculateAnnualMaintenanceExpiryDate(formData.annualMaintenanceStartDate);
    }
  }, [formData.annualMaintenanceStartDate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      if (name === "annualMaintenanceStartDate") {
        calculateAnnualMaintenanceExpiryDate(value);
      } else if (
        name === "domainPurchaseDate" ||
        name === "domainValidityYear"
      ) {
        calculateExpiryDate(
          updatedData.domainValidityYear,
          updatedData.domainPurchaseDate,
          "domainExpiryDate"
        );
      } else if (
        name === "hostingDateOfPurchase" ||
        name === "hostingValidity"
      ) {
        calculateExpiryDate(
          updatedData.hostingValidity,
          updatedData.hostingDateOfPurchase,
          "hostingDateOfExpiry"
        );
      }

      return updatedData;
    });
  };

  const handleHostingValidityChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        hostingValidity: value,
      };
      calculateExpiryDate(
        value,
        updatedData.hostingDateOfPurchase,

        "hostingDateOfExpiry"
      );
      return updatedData;
    });
  };

  const handleDomainValidityChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        domainValidityYear: value,
      };
      calculateExpiryDate(
        value,
        updatedData.domainPurchaseDate,

        "domainExpiryDate"
      );
      return updatedData;
    });
  };
  const calculateAnnualMaintenanceExpiryDate = (startDate) => {
    if (startDate) {
      const startDateObj = new Date(startDate);
      const expiryDateObj = new Date(startDateObj);
      expiryDateObj.setFullYear(startDateObj.getFullYear() + 1); // Add 1 year

      setFormData((prevData) => ({
        ...prevData,
        annualMaintenanceExpiryDate: expiryDateObj.toISOString().split("T")[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        annualMaintenanceExpiryDate: "",
      }));
    }
  };
  const calculateExpiryDate = (
    validity,
    purchaseDate,

    expiryDateField
  ) => {
    if (validity && purchaseDate) {
      const purchaseDateObj = new Date(purchaseDate);
      const expiryDateObj = new Date(purchaseDateObj);

      expiryDateObj.setFullYear(
        purchaseDateObj.getFullYear() + parseInt(validity)
      );

      setFormData((prevData) => ({
        ...prevData,
        [expiryDateField]: expiryDateObj.toISOString().split("T")[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [expiryDateField]: "",
      }));
    }
  };

  const handleProjectStatusChange = (event) => {
    const { name, checked } = event.target;

    setFormData((prevData) => {
      let updatedStatuses = new Set(prevData.projectStatus);

      if (name === "Closed") {
        if (checked) {
          // If "Closed" is checked, ensure other statuses are unchecked
          updatedStatuses = new Set(["Closed"]);
        } else {
          updatedStatuses.delete(name);
        }
      } else if (name === "Website" || name === "SocialMedia") {
        if (checked) {
          updatedStatuses.add(name);
          // Uncheck "Closed" if any of "Website" or "SocialMedia" is checked
          updatedStatuses.delete("Closed");
        } else {
          updatedStatuses.delete(name);
        }
      }

      return { ...prevData, projectStatus: Array.from(updatedStatuses) };
    });
  };
  const handleHostingStatusChange = (event) => {
    const { name, checked } = event.target;

    setFormData((prevData) => {
      const updatedStatus = { ...prevData.hostingBy };

      if (checked) {
        // Check the selected option
        updatedStatus.cs24 = name === "Purchase By Cs24";
        updatedStatus.client = name === "Purchase By Client";
        updatedStatus.notAvailable = name === "Not Available";
      } else {
        // Uncheck the deselected option
        updatedStatus[name] = false;
      }

      // Make sure that only one status can be selected at a time
      if (name !== "Purchase By Cs24") updatedStatus.cs24 = false;
      if (name !== "Purchase By Client") updatedStatus.client = false;
      if (name !== "Not Available") updatedStatus.notAvailable = false;

      return {
        ...prevData,
        hostingBy: updatedStatus,
      };
    });
  };

  const handleDomainStatusChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        domainBy: {
          cs24: name === "cs24",
          client: name === "client",
          notAvailable: name === "notAvailable",
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        domainBy: {
          ...prevData.domainBy,
          [name]: false,
        },
      }));
    }
  };

  const handleHostingCredentialsStatusChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setFormData((prevData) => ({
        ...prevData,

        hostingCredentials:
          name === "available" ? "Available" : "Not Available",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,

        hostingCredentials: {
          ...prevData.hostingCredentials,
          [name]: false,
        },
      }));
    }
  };

  const handleWpAdminCredentialsStatusChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        wordPressCredentials:
          name === "available" ? "Available" : "Not Available",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        wordPressCredentials: {
          ...prevData.wordPressCredentials,
          [name]: false,
        },
      }));
    }
  };
  const handleAnnualMaintenanceChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        annualMaintenance: name === "available" ? "Available" : "Not Available",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        annualMaintenance: {
          ...prevData.annualMaintenance,
          [name]: false,
        },
      }));
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!clientData || !clientData.clientID) {
      console.error("Client data is not defined or clientID is missing");
      return;
    }

    const hostingByMap = {
      cs24: "Purchase By Cs24",
      client: "Purchase By Client",
      notAvailable: "Not Available",
    };
    const domainByMap = {
      cs24: "Purchase By Cs24",
      client: "Purchase By Client",
      notAvailable: "Not Available",
    };
    const domainByValue =
      domainByMap[
      Object.keys(formData.domainBy).find((key) => formData.domainBy[key])
      ];
    const hostingByValue =
      hostingByMap[
      Object.keys(formData.hostingBy).find((key) => formData.hostingBy[key])
      ];
    const formDataToSend = {
      ...formData,
      hostingBy: hostingByValue,
      domainBy: domainByValue,
    };
    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/client-information/${clientData.clientID}`,
        formDataToSend
      );

      if (response.status === 200) {
        console.log("Form updated successfully!", response.data);
        alert("Form updated successfully!");
        onClose();
      } else {
        console.error("Unexpected response status:", response.status);
        alert("Form update failed!");
      }
    } catch (error) {
      console.error("Error updating form:", error.message);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        alert(
          `Form update failed: ${error.response.data.message || error.response.data
          }`
        );
      } else {
        alert("An error occurred while updating the form.");
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputClasses =
    "block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2";
  const boxClasses = "bg-white shadow-md rounded px-4 py-4";
  const boxStyle = {
    boxShadow: "0 4px 6px rgba(0, 175, 240, 0.3), 0 1px 3px rgba(0, 175, 240, 0.1)"
  };
  return (
    <div className="relative w-[1042px] mx-auto p-6 border-gray-200" style={{ borderRadius: '30px' }}>

      <h2 className="text-2xl font-bold text-gray-700 mb-2">
      Edit Client Entry
      </h2>
      <hr className="border-t border-gray-300 mx-[-24px] mb-4" />
      <form className="grid ml-4 grid-cols-1 gap-6" onSubmit={handleSubmit}>
        <div
          className="p-6 rounded-md"
          style={{
            borderTop: '1px dashed #ccc',
            borderRight: '1px dashed #ccc',
            borderBottom: '1px dashed #ccc',
            borderLeft: '1px dashed #ccc',
          }}
        >
          {/* Client Name and Company Name on the same line */}
          <div className="grid grid-cols-2 gap-4">
            <div className={`col-span-1 md:col-span-2`}>
              <h3 className="text-lg font-medium text-gray-900 mb-3">
                Project Status
              </h3>
              <div className="flex space-x-4">
                {["Website", "SocialMedia", "Closed"].map((status) => (
                  <label key={status} className="flex items-center">
                    <input
                      type="checkbox"
                      name={status}
                      checked={formData.projectStatus.includes(status)}
                      onChange={handleProjectStatusChange}
                      className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                    />
                    <span className="ml-2 text-sm text-gray-700">{status}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mt-4 mb-2">
                Client Name
              </label>
              <input
                type="text"
                value={formData.clientName}
                onChange={handleInputChange}
                name="clientName"
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700  mt-4 mb-2">
                Company Name
              </label>
              <input
                type="text"
                value={formData.companyName}
                onChange={handleInputChange}
                name="companyName"
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

          {/* Client Email and Client Phone on the same line */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mt-4 mb-2">
                Client Email
              </label>
              <input
                type="email"
                value={formData.clientEmail}
                onChange={handleInputChange}
                name="clientEmail"
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700  mt-4 mb-2">
                Client Phone
              </label>
              <input
                type="tel"
                value={formData.clientPhone}
                onChange={handleInputChange}
                name="clientPhone"
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

          {/* Project Start Date and Assigned To on the same line */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700  mt-4 mb-2">
                Project Start Date
              </label>
              <input
                type="date"
                value={formData.startDate}
                onChange={handleInputChange}
                name="startDate"
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700  mt-4 mb-2">
                Assigned To
              </label>
              <input
                type="text"
                value={formData.projectAssigned}
                onChange={handleInputChange}
                name="projectAssigned"
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

          {/* Domain Name and Hosting Name on the same line */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mt-4 mb-2">
                Domain Name
              </label>
              <input
                type="text"
                value={formData.domainName}
                onChange={handleInputChange}
                name="domainName"
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700  mt-4 mb-2">
                Hosting Name
              </label>
              <input
                type="text"
                value={formData.hostingName}
                onChange={handleInputChange}
                name="hostingName"
                className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              />
            </div>
          </div>

          {/* Technology on a separate line */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mt-4 mb-2">
              Technology
            </label>
            <input
              type="text"
              value={formData.technology}
              onChange={handleInputChange}
              name="technology"
              className={`${inputClasses}`}
              style={{ width: '440px' }} // Set width to 300 pixels
            />
          </div>


        </div>


        <div className={`col-span-1 md:col-span-2 mr-6`}>
          <div
            className="p-6 rounded-md"
            style={{
              borderTop: '1px dashed #ccc', // Light gray
              borderRight: '1px dashed #ccc',
              borderBottom: '1px dashed #ccc',
              borderLeft: '1px dashed #ccc',
            }}

          >
            <label className="text-lg font-semibold mb-2">
              Hosting Status
            </label>
            <div className="mt-3 flex space-x-4 items-center">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="Purchase By Cs24"
                  checked={formData.hostingBy.cs24 || false}
                  onChange={handleHostingStatusChange}
                  className=" h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                <span className="ml-2">CS24</span>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="Purchase By Client"
                  checked={formData.hostingBy.client || false}
                  onChange={handleHostingStatusChange}
                  className=" h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                <span className="ml-2">Client</span>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="Not Available"
                  checked={formData.hostingBy.notAvailable || false}
                  onChange={handleHostingStatusChange}
                  className=" h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                <span className="ml-2">Not Available</span>
              </label>
            </div>

            <div className="mt-5 flex flex-col md:flex-row md:space-x-4 ">
              {formData.hostingBy.cs24 && (
                <>
                  {/* Row for Hosting Validity and Date of Purchase */}
                  <div className="flex w-full space-x-4">
                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Hosting Validity
                      </label>
                      <select
                        value={formData.hostingValidity}
                        onChange={handleHostingValidityChange}
                        name="hostingValidity"
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      >
                        <option value="">Select Validity</option>
                        <option value="1">1 Year</option>
                        <option value="2">2 Years</option>
                        <option value="3">3 Years</option>
                        <option value="4">4 Years</option>
                        <option value="5">5 Years</option>
                      </select>
                    </div>

                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Hosting Date of Purchase
                      </label>
                      <input
                        type="date"
                        value={formData.hostingDateOfPurchase}
                        onChange={handleInputChange}
                        name="hostingDateOfPurchase"
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      />
                    </div>
                  </div>

                  {/* Row for Hosting Date of Expiry */}
                  <div className="w-full md:w-1/2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Hosting Date of Expiry
                    </label>
                    <input
                      type="date"
                      value={formData.hostingDateOfExpiry}
                      onChange={handleInputChange}
                      name="hostingDateOfExpiry"
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      readOnly
                    />
                  </div>
                </>
              )}
            </div>
            <div className={` col-span-1 md:col-span-2`}>
              <h3 className="text-lg font-semibold mb-2">
                Hosting Credentials Status
              </h3>
              <div className="flex flex-col sm:flex-row gap-4 mb-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="available"
                    checked={formData.hostingCredentials === "Available"}
                    onChange={handleHostingCredentialsStatusChange}
                    className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                  />
                  Available
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="notAvailable"
                    checked={formData.hostingCredentials === "Not Available"}
                    onChange={handleHostingCredentialsStatusChange}
                    className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                  />
                  Not Available
                </label>
              </div>

              {formData.hostingCredentials === "Available" && (
                <>
                  {/* Flex container for User ID and Password inputs */}
                  <div className="flex space-x-4 mb-4">
                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        User ID
                      </label>
                      <input
                        type="text"
                        value={formData.hostingUserId}
                        onChange={handleInputChange}
                        name="hostingUserId"
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      />
                    </div>

                    <div className="flex-1 mb-4 relative">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                      <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        value={formData.hostingPassword}
                        onChange={handleInputChange}
                        name="hostingPassword"
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="w-5 h-5" />
                        ) : (
                          <EyeIcon className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                    </div>
                  </div>
                </>
              )}
            </div>

          </div>
        </div>
              
        <div className={`col-span-1 md:col-span-2 mr-6`}>
          <div
            className="p-6 rounded-md"
            style={{
              borderTop: '1px dashed #ccc', // Light gray
              borderRight: '1px dashed #ccc',
              borderBottom: '1px dashed #ccc',
              borderLeft: '1px dashed #ccc',
            }}

          >
             <label className="text-lg font-semibold mb-2">
              Domain Status
            </label>
            <div className="mt-4 flex space-x-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="cs24"
                  checked={formData.domainBy.cs24 || false}
                  onChange={handleDomainStatusChange}
                  className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                CS24
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="client"
                  checked={formData.domainBy.client || false}
                  onChange={handleDomainStatusChange}
                  className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                Client
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="notAvailable"
                  checked={formData.domainBy.notAvailable || false}
                  onChange={handleDomainStatusChange}
                  className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                Not Available
              </label>


            </div>

            {formData.domainBy.cs24 && (
              <>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Domain Validity
                  </label>
                  <select
                    value={formData.domainValidityYear}
                    onChange={handleDomainValidityChange}
                    name="domainValidityYear"
                    className={`${inputClasses} w-full mb-2`}
                  >
                    <option value="">Select Validity</option>
                    <option value="1">1 Year</option>
                    <option value="2">2 Years</option>
                    <option value="3">3 Years</option>
                    <option value="4">4 Years</option>
                    <option value="5">5 Years</option>
                  </select>
                </div>

                <div className="flex mb-4">
                  <div className="flex-1 mr-2">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Domain Date of Purchase
                    </label>
                    <input
                      type="date"
                      value={formData.domainPurchaseDate}
                      onChange={handleInputChange}
                      name="domainPurchaseDate"
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                    />
                  </div>

                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Domain Date of Expiry
                    </label>
                    <input
                      type="date"
                      value={formData.domainExpiryDate}
                      onChange={handleInputChange}
                      name="domainExpiryDate"
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      readOnly
                    />
                  </div>
                </div>
              </>

            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="p-6 rounded-md" style={{ border: '1px dashed #ccc' }}>
            <h3 className="text-lg font-semibold mb-2">
              WP Admin Credentials Status
            </h3>
            <div className="flex flex-col sm:flex-row gap-4 mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="available"
                  checked={formData.wordPressCredentials === "Available"}
                  onChange={handleWpAdminCredentialsStatusChange}
                  className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                Available
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="notAvailable"
                  checked={formData.wordPressCredentials === "Not Available"}
                  onChange={handleWpAdminCredentialsStatusChange}
                  className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                Not Available
              </label>
            </div>

            {formData.wordPressCredentials === "Available" && (
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    WP Admin Username
                  </label>
                  <input
                    type="text"
                    name="wordPressUserId"
                    value={formData.wordPressUserId}
                    onChange={handleInputChange}
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>

                <div className="mb-4 relative">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    WP Admin Password
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="wordPressPassword"
                      value={formData.wordPressPassword}
                      onChange={handleInputChange}
                      className="max-w-md w-full p-2 pl-4 pr-10 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                    >
                      {showPassword ? (
                        <EyeSlashIcon className="w-5 h-5" />
                      ) : (
                        <EyeIcon className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>     </>
            )}
          </div>

          <div className="p-6 rounded-md" style={{ border: '1px dashed #ccc' }}>
            <h3 className="text-lg font-semibold mb-2">
              Annual Maintenance Contract
            </h3>
            <div className="flex flex-col sm:flex-row gap-4 mb-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="available"
                  checked={formData.annualMaintenance === "Available"}
                  onChange={handleAnnualMaintenanceChange}
                  className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                <label className="text-gray-600">Available</label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="notAvailable"
                  checked={formData.annualMaintenance === "Not Available"}
                  onChange={handleAnnualMaintenanceChange}
                  className="mr-2 h-4 w-4 text-indigo-600 accent-[#006CB5] border-gray-300 font-bold rounded focus:outline-none"
                />
                <label className="text-gray-600">Not Available</label>
              </div>
            </div>

            {formData.annualMaintenance === "Available" && (
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                    value={formData.annualMaintenanceStartDate || ""}
                    onChange={handleInputChange}
                    name="annualMaintenanceStartDate"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Expiry Date
                  </label>
                  <input
                    type="date"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                    value={formData.annualMaintenanceExpiryDate}
                    readOnly
                  />
                </div>
              </>
            )}
          </div>
        </div>



        <div className="col-span-1 md:col-span-2 mt-2 mr-6 flex justify-end">
          <button
            type="submit"
            className="bg-[#006CB5] text-white px-4 py-2 rounded-md "
          >
            Save
          </button>
        </div>
      </form>
    </div>



  );
};

export default EditClientInformation;
