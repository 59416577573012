import React, { useState } from "react";
import API_BASE_URL from "../../ApiConfig/ApiConfig";
import "react-datepicker/dist/react-datepicker.css";

const EventForm = () => {
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(null); // Changed to null initially
  const [country, setCountry] = useState("");
  const [work, setWork] = useState("");

  const [eventNameError, setEventNameError] = useState(false);
  const [eventDateError, setEventDateError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [workError, setWorkError] = useState(false);


  const handleCreateEvent = async () => {
    // Reset previous error states
    setEventNameError(false);
    setEventDateError(false);
    setCountryError(false);
    setWorkError(false);

    // Basic form validation
    if (!eventName || !eventDate || !country || !work) {
      if (!eventName) setEventNameError(true);
      if (!eventDate) setEventDateError(true);
      if (!country) setCountryError(true);
      if (!work) setWorkError(true);

      alert("Validation Error: All fields are required.");
      return;
    }

    const workValue = work === "Both" ? ["Post", "Video"] : [work];
    const countryValue =
      country === "Both" ? ["India", "United States of America"] : [country];

    try {
      const response = await fetch(`${API_BASE_URL}/events/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventName,
          eventDate,
          country: countryValue,
          work: workValue,
        }),
      });

      const data = await response.json();

      if (data.success) {
        alert("Event created successfully!");
        // Reset form fields
        setEventName("");
        setEventDate(null); // Reset to null
        setCountry("");
        setWork("");
      } else {
        alert(data.message || "An error occurred while creating the event.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while creating the event.");
    }
  };
  // Define handleClose function within the component



  return (
    <div className="min-w-full mx-auto p-6 mt-4 bg-white rounded-2xl shadow-md" style={{ borderRadius: '30px' }}>
  <div className="flex justify-between items-center mb-4">
    <h1 className="text-2xl font-bold text-gray-700">Add Event</h1>
  </div>

  {/* Horizontal line */}
  <hr className="border-t border-gray-300 mx-[-24px]" />

  {/* Input fields with responsive grid for larger screens */}
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mt-8 ml-6">
    <div>
      <label className="block text-lg font-medium text-gray-700 mb-2">
        Event Name {eventNameError && <span className="text-red-500">*</span>}
      </label>
      <input
        type="text"
        className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        placeholder="Enter Name"
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
      />
    </div>

    <div>
      <label className="block text-lg font-medium text-gray-700 mb-2">
        Country {countryError && <span className="text-red-500">*</span>}
      </label>
      <select
        className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
      >
        <option value="">Select Country</option>
        <option value="India">India</option>
        <option value="United States of America">United States of America</option>
        <option value="Both">Both</option>
      </select>
    </div>

    <div>
      <label className="block text-lg font-medium text-gray-700 mb-2">
        Work {workError && <span className="text-red-500">*</span>}
      </label>
      <select
        className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        value={work}
        onChange={(e) => setWork(e.target.value)}
      >
        <option value="">Select Work</option>
        <option value="Video">Video</option>
        <option value="Post">Post</option>
        <option value="Both">Both</option>
      </select>
    </div>

    <div>
      <label className="block text-lg font-medium text-gray-700 mb-2">
        Event Date {eventDateError && <span className="text-red-500">*</span>}
      </label>
      <input
        type="date"
        value={eventDate || ""}
        onChange={(e) => setEventDate(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
      />
    </div>
  </div>

  {/* Submit button aligned to the end */}
  <div className="flex justify-end">
    <button
      className="w-full sm:w-auto max-w-xs mt-6 bg-[#006CB5] text-white py-3 px-6 rounded-md font-bold transition duration-300 shadow-lg"
      onClick={handleCreateEvent}
    >
      Submit
    </button>
  </div>
</div>

  );
};

export default EventForm;
