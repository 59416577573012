import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import GoogleLogo from '../Dashboard-Con/logos/google 1.svg'; // Adjust the path as needed
import LinkedInLogo from '../Dashboard-Con/logos/linkedin (1) 1.svg';
import InstagramLogo from '../Dashboard-Con/logos/instagram.svg';
import FacebookLogo from '../Dashboard-Con/logos/facebook 1.svg';

const SocialMediaForm = () => {
  const [formData, setFormData] = useState({
    google: { id: '', password: '' },
    linkedin: { id: '', password: '' },
    instagram: { id: '', password: '' },
    facebook: { id: '', password: '' },
  });

  const [passwordVisible, setPasswordVisible] = useState({
    google: false,
    linkedin: false,
    instagram: false,
    facebook: false,
  }); // State to toggle password visibility for each platform

  const handlePasswordToggle = (platform) => {
    setPasswordVisible((prevState) => ({
      ...prevState,
      [platform]: !prevState[platform],
    }));
  };

  const [companyNames, setCompanyNames] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [errors, setErrors] = useState({});

  const platformIcons = {
    google: <img src={GoogleLogo} alt="Google logo" className="h-8 w-8 mr-2" />,
    linkedin: <img src={LinkedInLogo} alt="LinkedIn logo" className="h-8 w-8 mr-2" />,
    instagram: <img src={InstagramLogo} alt="Instagram logo" className="h-8 w-8 mr-2" />,
    facebook: <img src={FacebookLogo} alt="Facebook logo" className="h-8 w-8 mr-2" />,
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!selectedClient) {
      formIsValid = false;
      errors['selectedClient'] = 'Please select a client.';
    }

    Object.keys(formData).forEach((platform) => {
      if (!formData[platform].id || !formData[platform].password) {
        formIsValid = false;
        errors[platform] = 'Fields are required';
      }
    });

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (platform, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [platform]: {
        ...prevData[platform],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const postData = {
        companyName: selectedClient,
        google: {
          userId: formData.google.id,
          password: formData.google.password,
        },
        linkedin: {
          userId: formData.linkedin.id,
          password: formData.linkedin.password,
        },
        instagram: {
          userId: formData.instagram.id,
          password: formData.instagram.password,
        },
        facebook: {
          userId: formData.facebook.id,
          password: formData.facebook.password,
        },
      };

      const response = await axios.post(`${API_BASE_URL}/api/social-media-form`, postData);
      console.log('Data successfully sent:', response.data);
      window.alert('Data submitted successfully!');

      // Reset the form after successful submission
      setFormData({
        google: { id: '', password: '' },
        linkedin: { id: '', password: '' },
        instagram: { id: '', password: '' },
        facebook: { id: '', password: '' },
      });
      setSelectedClient('');
      setErrors({});

      await fetchCompanyNames();
    } catch (error) {
      console.error('Error sending data:', error.response?.data || error.message);
      window.alert('Error submitting data!');
    }
  };

  const fetchCompanyNames = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/names`);
      setCompanyNames(response.data);
    } catch (error) {
      console.error('Error fetching client names:', error);
    }
  };

  useEffect(() => {
    fetchCompanyNames();
  }, []);

  return (
    <div className="min-w-full mx-auto p-6 mt-4 bg-white rounded-2xl shadow-md" style={{ borderRadius: '30px' }}>
      <h1 className="text-2xl font-bold text-gray-700 mb-2">Social Media Portal</h1>
      <div className="-mx-6">
        <hr className="border-gray-300 mb-8" />
      </div>

      <div
        className="p-6 rounded-md"
        style={{
          borderTop: '1px dashed #ccc',
          borderRight: '1px dashed #ccc',
          borderBottom: '1px dashed #ccc',
          borderLeft: '1px dashed #ccc',
        }}
      >
        <div className="min-w-full mx-auto rounded-xl">
          <div className="mb-6">
            <label className="font-poppins text-[#292D32] text-16px font-bold leading-6 tracking-tight text-left mb-2 block" htmlFor="client-name">
              Company Name:
            </label>
            <div className="relative">
              <select
                id="client-name"
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 appearance-none pr-10"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                <option value="">Select Company Name</option>
                {companyNames.length > 0 ? (
                  companyNames.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))
                ) : (
                  <option value="">No clients available</option>
                )}
              </select>
              <span className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600 pointer-events-none">
                <svg
                  className="h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </span>
            </div>

            {errors.selectedClient && <p className="text-red-500 text-sm mt-1">{errors.selectedClient}</p>}
          </div>

          {Object.keys(formData).map((platform) => (
            <div key={platform} className="mb-4 p-2 rounded-md">
              <div className="flex items-center mb-4">
                {platformIcons[platform]}
                <h3 className="text-lg font-semibold text-gray-700">
                  {platform.charAt(0).toUpperCase() + platform.slice(1)} Credentials
                </h3>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium mb-1" htmlFor={`${platform}-id`}>
                    ID
                  </label>
                  <input
                    type="text"
                    id={`${platform}-id`}
                    placeholder="Enter ID"
                    value={formData[platform].id}
                    onChange={(e) => handleChange(platform, 'id', e.target.value)}
                    className="max-w-full w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                  {errors[platform] && !formData[platform].id && <p className="text-red-500 text-sm mt-1">{errors[platform]}</p>}
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1" htmlFor={`${platform}-password`}>
                    Password
                  </label>
                  <div className="relative">
                    <input
                      type={passwordVisible[platform] ? 'text' : 'password'}
                      id={`${platform}-password`}
                      placeholder="Enter Password"
                      value={formData[platform].password}
                      onChange={(e) => handleChange(platform, 'password', e.target.value)}
                      className="max-w-full w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                    />
                    <span
                      className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                      onClick={() => handlePasswordToggle(platform)}
                    >
                      {passwordVisible[platform] ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-600" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-600" />
                      )}
                    </span>
                  </div>
                  {errors[platform] && !formData[platform].password && <p className="text-red-500 text-sm mt-1">{errors[platform]}</p>}
                </div>
              </div>
            </div>
          ))}

          <div className="flex justify-end mt-8">
            <button
              onClick={handleSubmit}
              className="bg-[#006CB5] text-white font-semibold py-2 px-8 rounded-[8px] hover:bg-blue-700 transition duration-300 shadow-lg w-full sm:w-auto"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaForm;
