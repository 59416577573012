import React, { useState } from "react";
import API_BASE_URL from "../../ApiConfig/ApiConfig";
import { FaStar, FaRegStar, FaTimes } from 'react-icons/fa';
const UserItem = ({ user, fetchUsers }) => {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState(""); // State to handle viewing modal
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [progressData, setProgressData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fetchProgressData = async (username) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/feedback/username/${username}`
      );
      const data = await response.json();
      setProgressData(data);
    } catch (error) {
      console.error("Error fetching progress data:", error);
    }
  };
  const onFeedbackClick = () => {
    fetchProgressData(user.username); // Fetch feedback data for the selected user
    setIsModalOpen(true);
  };
 
  
  const [editData, setEditData] = useState({
    username: user.username,
    password: "",
    name: user.name,
    profileImage: user.profileImage,
    role: user.role,
    address: user.address,
    designation: user.designation,
    dob: formatDate(user.dob),
    bloodGroup: user.bloodGroup,
    officialEmail: user.officialEmail,
    personalEmail: user.personalEmail,
    empJoiningDate: formatDate(user.empJoiningDate),
    phone: user.phone,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleEdit = async () => {
    try {
      const formData = new FormData();
      formData.append("username", editData.username);
      formData.append("password", editData.password);
      formData.append("name", editData.name);
      formData.append("officialEmail", editData.officialEmail);
      formData.append("personalEmail", editData.personalEmail);
      formData.append("phone", editData.phone);
      formData.append("address", editData.address);
      formData.append("designation", editData.designation);
      formData.append("role", editData.role);
      formData.append("dob", editData.dob);
      formData.append("bloodGroup", editData.bloodGroup);
      formData.append("empJoiningDate", editData.empJoiningDate);
      if (selectedFile) {
        formData.append("profileImage", selectedFile);
      }
      const response = await fetch(
        `${API_BASE_URL}/api/auth/users/${user._id}`,
        {
          method: "PUT",
          body: formData,
        }
      );
      if (response.ok) {
        setIsEditing(false);
        fetchUsers();
      }
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${padZero(month)}-${padZero(day)}`;
  }

  function padZero(number) {
    return (number < 10 ? "0" : "") + number;
  }

  const handleDelete = () => {
    setIsConfirmingDelete(true); // Show confirmation modal
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/auth/users/${user._id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setIsConfirmingDelete(false);
        fetchUsers();
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const cancelDelete = () => {
    setIsConfirmingDelete(false); // Hide confirmation modal
  };
  const handleFeedbackSubmit = async () => {
    const feedbackData = {
      userId: user._id, // Ensure user._id is valid
      rating,
      feedback,
      selectedMonth, // Ensure selectedMonth is set correctly
      selectedYear, // Ensure selectedYear is set correctly
    };
  
    if (!feedback || !selectedMonth || !selectedYear) {
      alert("Please fill in all the required fields.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/feedback/submit-feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackData),
      });

      if (!response.ok) {
        const result = await response.json();
        console.error("Feedback submission failed:", result.message);
        alert(result.message || "Error submitting feedback");
        return;
      }

      const result = await response.json();
      alert("Feedback submitted successfully!");
      setIsFeedbackOpen(false);
    } catch (error) {
      console.error("Feedback submission failed:", error);
      alert("Something went wrong while submitting feedback.");
    }
  };

  return (
    <div>
      <ul className="divide-y divide-gray-200">
        <li key={user._id} className="py-4 flex justify-between items-center">
          <div className="flex-1 min-w-0 text-left font-semibold text-[#292D32] overflow-hidden truncate">
            {user.username}
          </div>
          <div className="flex-1 min-w-0 text-left font-semibold text-[#292D32] pl-4 overflow-hidden truncate">
            {user.designation}
          </div>
          <div className="flex-1 min-w-0 text-left font-semibold text-[#292D32] pl-4 overflow-hidden truncate">
            {user.role}
          </div>
          <div className="flex-1 min-w-0 text-left font-semibold text-[#292D32] pl-4 overflow-hidden truncate">
            <button
              className="text-[#006CB5]"
              onClick={() => onFeedbackClick(user)} // Handle feedback button click
            >
              Progress Report
            </button>
          </div>
          <div className="flex-1 min-w-0 text-right font-semibold text-[#292D32] overflow-hidden truncate">
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsFeedbackOpen(true)}
                className="text-yellow-500 hover:text-yellow-700"
              >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/8ae9a3d53fa73461260059cf7c99190181ab172fdb41ed6c20c7caeeb8807954?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6"
                  alt="Feedback Icon"
                  className="w-6 h-6 object-contain"
                />
              </button>
              <button
                onClick={() => setIsViewing(true)} // Pass user to handleView
                className="text-blue-500 hover:text-blue-700"
              >
                <i className="fas fa-eye text-sm"></i>
              </button>
              <button
                onClick={() => setIsEditing(true)}
                className="text-[#008767] hover:text-green-700"
              >
                <i className="fas fa-edit text-sm"></i>
              </button>
              <button
                onClick={() => handleDelete(user._id)}
                className="text-red-500 hover:text-red-700"
              >
                <i className="fas fa-trash-alt text-sm"></i>
              </button>
            </div>
          </div>
           {/* Modal for feedback */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-[800px] max-w-full relative">
            <button
              onClick={() => setIsModalOpen(false)} // Close the modal
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 focus:outline-none"
            >
              <FaTimes size={24} />
            </button>

            <h3 className="text-2xl font-semibold text-[#006CB5] mb-4">Performance Record</h3>
            <div className="overflow-x-auto">
              <table className="w-full table-auto border-collapse">
                <thead>
                  <tr className="text-left border-t border-b border-gray-300">
                    <th className="px-6 py-3 text-sm font-medium text-gray-600 text-left">Rating</th>
                    <th className="px-6 py-3 text-sm font-medium text-gray-600 text-left">Comment</th>
                    <th className="px-6 py-3 text-sm font-medium text-gray-600 text-center">Months</th>
                    <th className="px-6 py-3 text-sm font-medium text-gray-600 text-center">Year</th>
                  </tr>
                </thead>

                <tbody>
                  {progressData.length > 0 ? (
                    progressData.map((item, index) => (
                      <tr key={index} className="border-b hover:bg-gray-50">
                        <td className="px-6 py-4 text-sm text-left">
                          {[...Array(5)].map((_, starIndex) => (
                            starIndex < item.rating ? (
                              <FaStar key={starIndex} className="inline text-yellow-500" />
                            ) : (
                              <FaRegStar key={starIndex} className="inline text-yellow-500" />
                            )
                          ))}
                        </td>
                        <td className="px-6 py-4 text-sm text-left">{item.feedback}</td>
                        <td className="px-6 py-4 text-sm text-center">{item.month}</td>
                        <td className="px-6 py-4 text-sm text-center">{item.year}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="px-6 py-4 text-sm text-center">No feedback data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
        </li>
      </ul>
      {/* feedback Section */}
      {isFeedbackOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <section className="flex flex-col items-center py-10 bg-white rounded-[30px] shadow-[0px_10px_60px_rgba(226,236,249,0.5)] w-[504px]">
            <div className="flex gap-5 justify-between w-[428px] text-xl font-bold text-center text-zinc-800">
              <h2>Feedback</h2>
              <div className="relative inline-block">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c611447e1cb2ba78e690ab56218952986e0fa3949494c3d2104179b4a8a53301?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6"
                  alt="Logo"
                  onClick={() => setIsFeedbackOpen(false)}
                  className="w-8 h-8 cursor-pointer"
                />
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8aa3987e4ee2342b66bd1f1bd15256be6ff5b7dcfcb3b170033386b6c461564b?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6"
              alt=""
              className="w-full mt-4"
            />
            <form className="flex flex-col items-center w-full mt-5">
              <div className="flex gap-8 w-[428px] text-sm">
                <div className="flex flex-col flex-1">
                  <label className="font-medium text-black">Month</label>
                  <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="px-6 py-5 mt-2.5 text-gray-400 rounded-lg border border-gray-300 shadow-sm focus:outline-none"
                  >
                    <option>Select Month</option>
                    <option>January</option>
                    <option>February</option>
                    <option>March</option>
                    <option>April</option>
                    <option>May</option>
                    <option>June</option>
                    <option>July</option>
                    <option>August</option>
                    <option>September</option>
                    <option>October</option>
                    <option>November</option>
                    <option>December</option>
                  </select>

                  <select
  value={selectedYear}
  onChange={(e) => setSelectedYear(e.target.value)}
  className="px-6 py-5 mt-2.5 text-gray-400 rounded-lg border border-gray-300 shadow-sm focus:outline-none"
>
  <option value="">Select Year</option>
  {Array.from({ length: 10 }).map((_, index) => {
    const year = 2020 + index; // Start from 2020
    return <option key={year} value={year}>{year}</option>;
  })}
</select>

                </div>
              </div>

              {/* Star Rating */}
              <div className="flex gap-3 mt-6 mb-4">
                {Array.from({ length: 5 }).map((_, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer ${
                      index < rating ? "text-yellow-500" : "text-gray-400"
                    }`} // Change color based on selected rating
                    onClick={() => setRating(index + 1)} // Update rating on click
                  >
                    <i className={`fas fa-star text-3xl`} />{" "}
                    {/* Larger stars */}
                  </div>
                ))}
              </div>

              {/* Feedback Text Area */}
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Your feedback here..."
                className="w-[428px] p-3 mt-3 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              ></textarea>

              <div className="flex justify-between w-[428px] mt-5">
                <button
                  type="button"
                  className="w-full py-2 mt-8 text-base text-center text-white bg-sky-700 outline-none rounded-lg shadow-sm "
                  onClick={handleFeedbackSubmit}
                >
                  Submit Feedback
                </button>
              </div>
            </form>
          </section>
        </div>
      )}

      {/* Modal for viewing user details */}

      {isViewing && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="flex flex-col items-center self-center px-10 py-4 ml-2.5 border border-gray-200 bg-white rounded-[20px] shadow-[0px_10px_30px_rgba(226,236,249,0.5)] w-full max-w-[600px] max-md:px-5 mt-0">
            <div className="w-28 h-28 mb-4">
              <img
                src={`${API_BASE_URL}/${
                  user.profileImage ? user.profileImage : "defaultImage"
                }`}
                alt={`${user.name}'s profile`}
                className="w-full h-full object-cover rounded-full border-4 border-sky-700 shadow-md"
              />
            </div>
            <h2 className="text-xl font-semibold text-sky-700">{user.name}</h2>
            <p className="mt-1 text-base text-neutral-700">
              {user.designation}
            </p>

            <div className="grid grid-cols-1 md:pl-12 md:ml-0 md:grid-cols-2 gap-6 mt-6 w-full">
              {/* Left Column */}
              <div className="flex flex-col gap-3">
                {[
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5c6a189795f00dce03e6e57e56e5861e8e77ddbda0f1b5466a5f595fb9d11351?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Username",
                    value: user.username,
                  },
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5c6a189795f00dce03e6e57e56e5861e8e77ddbda0f1b5466a5f595fb9d11351?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Role",
                    value: user.role,
                  },
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5c389ff2da33585eead8369ce748451883f0891f864a9bd3fa37fb58cb18d148?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Blood Group",
                    value: user.bloodGroup,
                  },
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6f22d3b931dbd3209fd858790e22fc4b49caa1bbe854feeb81e4af8546857d0b?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Joining Date",
                    value: formatDate(user.empJoiningDate),
                  },
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/66f9dc3cfe9f3f85ab013ca5b0ab3645581bc6b908ab9cab2dedbd9fd6d72190?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Date of Birth",
                    value: formatDate(user.dob),
                  },
                ].map((info, index) => (
                  <div key={index} className="flex gap-3 items-start">
                    <img
                      src={info.icon}
                      alt=""
                      className="object-contain w-[25px] h-[25px]"
                    />
                    <div className="flex flex-col">
                      <span className="text-xs text-gray-400">
                        {info.label}
                      </span>
                      <span className="mt-1 text-sm font-medium text-zinc-800">
                        {info.value}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {/* Right Column */}
              <div className="flex flex-col gap-3">
                {[
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c70c1116dc73930042f4c7deae25be5ab101c5b7fe90a6091c8e379bdf3924dd?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Phone Number",
                    value: user.phone,
                  },
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b3a1163baaa1914a704b08535059799aacc1cf78e7016d47ef6b10cb92ebe46c?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Official Email",
                    value: user.officialEmail,
                  },
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b3a1163baaa1914a704b08535059799aacc1cf78e7016d47ef6b10cb92ebe46c?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Personal Email",
                    value: user.personalEmail,
                  },
                  {
                    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5c6a189795f00dce03e6e57e56e5861e8e77ddbda0f1b5466a5f595fb9d11351?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6",
                    label: "Address",
                    value: user.address,
                  },
                ].map((info, index) => (
                  <div key={index} className="flex gap-3 items-start">
                    <img
                      src={info.icon}
                      alt=""
                      className="object-contain w-[25px] h-[25px]"
                    />
                    <div className="flex flex-col">
                      <span className="text-xs text-gray-400">
                        {info.label}
                      </span>
                      <span className="mt-1 text-sm font-medium text-zinc-800">
                        {info.value}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-center w-full mt-3">
              <button
                onClick={() => setIsViewing(false)}
                className="w-full md:w-36 px-10 py-2 text-base font-medium text-center text-white bg-gray-500 rounded-md shadow hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for editing user details */}
      {/* Modal for editing user details */}
      {isEditing && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="bg-white rounded-3xl shadow-md p-6 w-full max-w-4xl"
            style={{
              maxWidth: "900px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 20px",
              borderRadius: "30px",
            }}
          >
            <div className="relative mb-6">
              <h2 className="text-2xl font-bold text-gray-800">Edit User</h2>
              <button
                onClick={() => setIsEditing(false)} // Close the edit form
                className="absolute top-0 right-2 w-7 h-7 flex items-center justify-center bg-white text-gray-600 text-xl rounded-full border-2 border-gray-600 hover:bg-gray-200 focus:outline-none"
              >
                &times;
              </button>
            </div>
            <hr className="border-t border-gray-300 mx-[-24px] mb-6" />

            {/* Scrollable form content with reduced height */}
            <div className="max-h-[50vh] p-4 overflow-y-auto mb-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    value={editData.username}
                    onChange={handleChange}
                    placeholder="Enter your username"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Password
                  </label>
                  <input
                    type="text"
                    name="password"
                    value={editData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Employee Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={editData.name}
                    onChange={handleChange}
                    placeholder="Enter your full name"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Role
                  </label>
                  <select
                    name="role"
                    value={editData.role}
                    onChange={handleChange}
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  >
                    <option value="">Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="web">Web Developer</option>
                    <option value="social">Social Media</option>
                    <option value="user">User</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={editData.address}
                    onChange={handleChange}
                    placeholder="Enter your address"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Designation
                  </label>
                  <input
                    type="text"
                    name="designation"
                    value={editData.designation}
                    onChange={handleChange}
                    placeholder="Enter your designation"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    name="dob"
                    value={editData.dob}
                    onChange={handleChange}
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Blood Group
                  </label>
                  <select
                    name="bloodGroup"
                    value={editData.bloodGroup}
                    onChange={handleChange}
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  >
                    <option value="">Select Blood Group</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Official Email
                  </label>
                  <input
                    type="email"
                    name="officialEmail"
                    value={editData.officialEmail}
                    onChange={handleChange}
                    placeholder="Enter official email"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Personal Email
                  </label>
                  <input
                    type="email"
                    name="personalEmail"
                    value={editData.personalEmail}
                    onChange={handleChange}
                    placeholder="Enter personal email"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Employee Joining Date
                  </label>
                  <input
                    type="date"
                    name="empJoiningDate"
                    value={editData.empJoiningDate}
                    onChange={handleChange}
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={editData.phone}
                    onChange={handleChange}
                    placeholder="Enter phone number"
                    className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Profile Image
                </label>
                <input
                  type="file"
                  name="profileImage"
                  onChange={handleFileChange}
                  className="w-[100%] p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                />
              </div>
            </div>

            <div className="flex space-x-4 mt-6 mr-4 justify-end">
              <button
                onClick={handleEdit}
                className="py-2 px-6 bg-[#006CB5] text-white font-semibold rounded-md shadow focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                Submit
              </button>
              {/* <button
          onClick={() => setIsEditing(false)}
          className="py-2 px-6 bg-gray-500 text-white font-semibold rounded-md shadow hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          Cancel
        </button> */}
            </div>
          </div>
        </div>
      )}

      {/* Confirmation modal for deletion */}
      {isConfirmingDelete && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
          <div className="bg-white p-5 sm:p-6 rounded-lg shadow-lg w-full max-w-xs sm:max-w-md">
            <div className="flex justify-center mb-4">
              <i className="fas fa-trash-alt text-red-500 text-4xl"></i>
            </div>
            <h2 className="text-lg sm:text-xl pt-3 font-bold text-gray-800 mb-1 text-center">
              Are You Sure for Delete?
            </h2>
            <p className="text-sm sm:text-base pt-5 text-gray-600 text-center mb-4">
              Do you really want to delete: <strong>{user.name}</strong>?
            </p>
            <div className="flex justify-center mt-7 space-x-12">
              <button
                className="bg-[#ECECEC] text-black font-bold px-10 py-2 rounded-md hover:bg-[#D3D3D3]"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white font-bold px-10 py-2 rounded-md hover:bg-red-600"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserItem;
