import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SidebarNav from './SidebarNav';

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const mainContentRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      mainContentRef.current &&
      !mainContentRef.current.contains(event.target)
    ) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Top Navbar */}
      <TopNavbar toggleSidebar={toggleSidebar} />

      <div className="flex flex-1 pt-16">
        {/* Sidebar for desktop view */}
        <div
          ref={sidebarRef}
          className="hidden lg:flex lg:w-64 lg:flex-shrink-0 fixed top-16 left-0 bottom-0 z-40"
        >
          <SidebarNav isOpen={true} />
        </div>

        {/* Sidebar for mobile view */}
        <div
          ref={sidebarRef}
          className={`lg:hidden  fixed top-16 left-0 bottom-0 shadow-lg transition-transform duration-300 ease-in-out z-[999] ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
          style={{ width: '200px' }}
        >
          <SidebarNav isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </div>

        {/* Main Content */}
        <main
          ref={mainContentRef}
          className={`flex-1 p-6 bg-[#FAFBFF] transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64 lg:ml-64' : 'lg:ml-64'}`}
        >
          <Outlet /> {/* This is where the nested routes will be rendered */}
        </main>
      </div>
    </div>
  );
};

export default Layout;
