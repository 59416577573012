import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import './Login.css';
import Cookies from 'js-cookie'; 
import Loader from '../Loader/Loader'; // Import the Loader component

const AdminLogin = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/admin/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        Cookies.set('Admintoken', token, {
          expires: 7,
          secure: true,
          sameSite: 'Strict',
        });

        const retrievedToken = Cookies.get('token');
        console.log('Token from cookie:', retrievedToken);
        
        navigate('/admin/dashboard');
      } else {
        const data = await response.json();
        setError(data.message);
      }
    } catch (error) {
      setError('An unexpected error occurred.');
    }
  };

  const handleForgetPassword = async () => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/forget-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email }),
      });

      const data = await response.json();
      if (response.ok) {
        alert('A reset link has been sent to your email.');
        setShowForgetPassword(false);
        setEmail('');
      } else {
        setError(data.message || 'Failed to send reset link.');
      }
    } catch (error) {
      setError('An unexpected error occurred.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col px-6 py-8 bg-white rounded-2xl shadow-lg max-w-md w-full">
        {error && <p className="mt-2 text-center text-red-600 text-sm">{error}</p>}
        <form onSubmit={handleSubmit} className="flex flex-col">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0fcc125226c73da36abdf34acf339a6f21826d8ea21cc98dfb9138146deea8af?placeholderIfAbsent=true&apiKey=208ba67bf72b486f9889b172fa1cace6"
            alt="Login logo"
            className="object-contain self-center w-32 mb-4" // Smaller logo
          />
          <h2 className="self-center text-lg font-semibold">Welcome to Creativestudio24</h2>
          <p className="self-center mt-1 text-xs text-gray-400">Enter your username to continue</p>

          <label htmlFor="username" className="self-start mt-4 text-sm">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            className="px-4 py-2 mt-2 text-sm text-gray-400 rounded-lg border  border-gray-300  shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
            placeholder="abc@123"
            value={formData.username}
            onChange={handleChange}
            required
          />

          <label htmlFor="password" className="self-start mt-4 text-sm">Password</label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              className="w-full px-4 py-2 mt-2 text-sm text-gray-400 rounded-lg  border  border-gray-300  shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              placeholder="12341234"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            >
              <i className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash '}`}></i>
            </span>
          </div>

          <div  className="self-end mt-2 cursor-pointer text-sm" onClick={() => setShowForgetPassword(!showForgetPassword)}>
            Forgot Password?
          </div>

          <button
            type="submit"
            className="self-stretch px-24 py-2 mt-4 font-medium text-center text-white bg-sky-700 rounded-xl shadow-sm min-h-[35px]"
          >
            Login
          </button>

          {showForgetPassword && (
            <>
              <label htmlFor="email" className="self-start mt-4 text-sm">Email Address</label>
              <input
                required
                className="px-4 py-2 mt-2 text-sm text-gray-400 rounded-lg  border  border-gray-300  shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="button"
                onClick={handleForgetPassword}
                className="self-stretch px-24 py-2 mt-6 font-medium text-center text-white bg-sky-700 rounded-xl shadow-sm min-h-[35px]"
              >
                 {loading ? <Loader /> : 'Send Reset Email'}
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
