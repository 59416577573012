import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode"; // Import jwt-decode
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import Cookies from 'js-cookie';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
// Set the app element for accessibility
Modal.setAppElement("#root");

const ViewEvent = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const [formData, setFormData] = useState({
    eventName: '',
    eventDate: null,
    country: '',
    work: ''
  });
  const [showPopup, setShowPopup] = useState(false); // State to handle popup visibility
  const [popupMessage, setPopupMessage] = useState(''); // State to handle popup message
  const countries = ['India', 'United States of America', 'Both'];
  const works = ['Video', 'Post', 'Both'];

  // Function to check the user's role
  const checkUserRole = () => {
    const token = Cookies.get("token"); // Retrieve token from token
    if (token) {
      const decodedToken = jwtDecode(token); // Decode the token to get the user's role
      const { role } = decodedToken;
      return role;
    }
    return null;
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/events/fetch`);
        // Sort events by eventDate in descending order
        const sortedEvents = response.data.events.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
        setEvents(sortedEvents);
        setLoading(false);
      } catch (error) {
        setError('Error fetching events.');
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);


  const openEditModal = (event) => {
    const role = checkUserRole();
    if (role === 'web' || role === 'user') {
      setPopupMessage('You are not authorized to edit this event.');
      setShowPopup(true); // Show the popup if the user is not authorized
      return;
    }

    setSelectedEvent(event);

    const workValue = event.work.includes("Post") && event.work.includes("Video") ? "Both" : event.work[0];
    const countryValue = event.country.includes("India") && event.country.includes("United States of America") ? "Both" : event.country[0];

    setFormData({
      eventName: event.eventName,
      eventDate: new Date(event.eventDate),
      country: countryValue,
      work: workValue,
    });
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEvent(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      eventDate: date
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/events/update/${selectedEvent._id}`, {
        ...formData,
        country: formData.country === 'Both' ? ['India', 'United States of America'] : [formData.country],
        work: formData.work === 'Both' ? ['Video', 'Post'] : [formData.work],
      });

      // Fetch the updated list of events
      const response = await axios.get(`${API_BASE_URL}/events/fetch`);
      setEvents(response.data.events);

      closeEditModal();
    } catch (error) {
      setError('Error updating event.');
    }
  };

  const handleDelete = async (eventId) => {
    const role = checkUserRole();
    if (role === 'web' || role === 'user') {
      setPopupMessage('You are not authorized to delete this event.');
      setShowPopup(true);
      return;
    }


    setEventIdToDelete(eventId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/events/delete/${eventIdToDelete}`);

      // Filter out the deleted event from the state
      setEvents(events.filter((event) => event._id !== eventIdToDelete));
      setShowDeleteModal(false);
    } catch (error) {
      setError('Error deleting event.');
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10; // Set to whatever number you want for each page
  const totalPages = Math.ceil(events.length / recordsPerPage);

  // Derived data for pagination
  const startIndex = (currentPage - 1) * recordsPerPage;
  const filteredRecords = events.slice(startIndex, startIndex + recordsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="min-w-full mx-auto p-6 mt-4 bg-white rounded-2xl shadow-md " style={{ borderRadius: '30px' }}>      <h1 className="text-xl sm:text-2xl font-bold text-[#292D32]  text-left mb-4">Upcoming Events</h1>
      <hr className="border-t border-gray-300 mx-[-24px] " />

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white  border-gray-300 rounded-md">
          <thead className=" text-[#B5B7C0]">
            <tr>
              <th className="p-4 text-sm font-semibold border-b text-left  md:text-base">Event Name</th>
              <th className="px-4  text-sm font-semibold  border-b text-left ">Event Date</th>
              <th className="px-4  text-sm font-semibold  border-b text-left ">Week Day</th>
              <th className="px-4 text-sm font-semibold  border-b text-left ">Country</th>
              <th className="px-4 text-sm font-semibold  border-b text-left ">Work</th>
              <th className="px-7 text-sm font-semibold lg:text-left border-b text-left ">Actions</th>

            </tr>
          </thead>
          <tbody>
            {filteredRecords.length === 0 ? (
              <tr>
                <td colSpan="6" className="p-4 text-center text-gray-500">No events found</td>
              </tr>
            ) : (
              filteredRecords.map((event) => (
                <tr key={event._id} className="hover:bg-gray-50">
                  <td className="p-4 border-b text-left text-sm font-semibold text-[#006CB5]">{event.eventName}</td>
                  <td className="p-4 border-b text-left text-sm font-semibold text-gray-700 md:text-base">
                    {new Date(event.eventDate).toLocaleDateString("en-US", { day: "numeric", month: "short", year: "numeric" })}
                  </td>
                  <td className={`p-4 border-b text-left text-sm font-semibold text-gray-700 ${new Date(event.eventDate).toLocaleDateString("en-US", { weekday: 'long' }) === "Sunday" ? "text-red-500" : ""
                    }`}>
                    {new Date(event.eventDate).toLocaleDateString("en-US", { weekday: 'long' })}
                  </td>
                  <td className="p-4 border-b text-left  text-sm font-semibold text-gray-700">
                    {Array.isArray(event.country) ? event.country.join(', ') : event.country}
                  </td>
                  <td className="p-4 border-b text-left  text-sm font-semibold text-gray-700">
                    {Array.isArray(event.work) ? event.work.join(', ') : event.work}
                  </td>
                  <td className="border-b text-left p-4 text-sm font-semibold text-gray-700">
                    <div className="flex justify-center space-x-2">
                      <button
                        onClick={() => openEditModal(event)}
                        className="text-[#008767] text-sm md:text-sm"
                      >
                        <FaEdit className="text-base md:text-sm" />
                      </button>
                      <button
                        onClick={() => handleDelete(event._id)}
                        className="text-red-500 hover:text-red-700 text-sm md:text-sm"
                      >
                        <FaTrashAlt className="text-base md:text-sm" />
                      </button>
                    </div>
                  </td>

                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-2">
          <p className="text-xs text-gray-600">
            Showing {startIndex + 1} to {Math.min(startIndex + recordsPerPage, filteredRecords.length)} of{" "}
            {filteredRecords.length} entries
          </p>
          <div className="flex space-x-2">
            {/* Previous button */}
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-2 py-0.5 bg-gray-200 rounded-sm"
            >
              {"<"}
            </button>

            {/* Page numbers with ellipsis */}
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter((page) => {
                return (
                  page === 1 || // Always show the first page
                  page === totalPages || // Always show the last page
                  (page <= 3 || (page >= currentPage - 1 && page <= currentPage + 1)) // Show pages around current page
                );
              })
              .map((page, index, filteredPages) => (
                <React.Fragment key={page}>
                  {index > 0 && page !== filteredPages[index - 1] + 1 && (
                    <span className="px-1">...</span> // Show ellipsis for non-consecutive pages
                  )}
                  <button
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-0.5 rounded-sm ${currentPage === page ? "bg-[#006CB5] text-white" : "bg-gray-200"
                      }`}
                  >
                    {page}
                  </button>
                </React.Fragment>
              ))}

            {/* Next button */}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-2 py-0.5 bg-gray-200 rounded-sm"
            >
              {">"}
            </button>
          </div>
        </div>

      </div>



      {/* Modal for editing event */}
      <div className="relative bg-gray-100">
        {/* Modal for editing event */}
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          className="relative mx-auto p-6 bg-white rounded-lg shadow-lg w-full max-w-lg md:max-w-2xl lg:max-w-4xl"
          overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50" // Centers the modal
          contentLabel="Edit Event"
        >
          {selectedEvent && (
            <div className="p-4">
              {/* Modal Header */}
              <div className="flex justify-between items-center mb-4 ">
                <h2 className="text-2xl font-bold text-gray-700 ">Edit Event Details</h2>
                <button
                  onClick={closeEditModal}
                  className="text-gray-600 hover:text-gray-900 rounded-full h-8 w-8 flex items-center justify-center border-2 border-gray-600"
                >
                  ✕
                </button>


              </div>
              <hr className="border-t  border-gray-300 mx-[-38px]" />
              {/* Form */}
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 sm:grid-cols-2 mt-8 gap-4">
                  {/* Event Name */}
                  <div>
                    <label htmlFor="eventName" className="block text-gray-700 font-medium mb-1">
                      Event Name
                    </label>
                    <input
                      type="text"
                      id="eventName"
                      name="eventName"
                      value={formData.eventName}
                      onChange={handleInputChange}
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      placeholder="Enter Event Name"
                    />
                  </div>

                  {/* Event Date */}
                  <div>
                    <label htmlFor="eventDate" className="block text-gray-700 font-medium mb-1">
                      Event Date
                    </label>
                    <DatePicker
                      selected={formData.eventDate}
                      onChange={handleDateChange}
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Select a Date"
                      wrapperClassName="w-full"
                    />
                  </div>

                  {/* Country */}
                  <div>
                    <label htmlFor="country" className="block text-gray-700 font-medium mb-1">
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Work */}
                  <div>
                    <label htmlFor="work" className="block text-gray-700 font-medium mb-1">
                      Work
                    </label>
                    <select
                      id="work"
                      name="work"
                      value={formData.work}
                      onChange={handleInputChange}
                      className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                    >
                      <option value="">Select Work</option>
                      {works.map((work) => (
                        <option key={work} value={work}>
                          {work}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Form Actions */}
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600"
                  >
                    Save
                  </button>
                </div>
              </form>

            </div>
          )}
        </Modal>
        {showDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
              <div className="flex flex-col items-center mb-4">
                <i className="fas fa-trash-alt text-red-500 text-4xl mb-4"></i>
                <h2 className="text-xl font-bold mb-4 text-center">Are You Sure for Delete?</h2>
                <p className="text-center mb-2">
                  Are you sure you want to delete this event?
                </p>
              </div>
              <div className="flex justify-around  mt-4">
                <button
                  className="bg-gray-300 px-4 py-2 rounded"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded"
                  onClick={confirmDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}





        {/* Popup */}
        {showPopup && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm sm:max-w-md lg:max-w-lg">
              <h2 className="text-2xl font-bold mb-4">Access Denied</h2>
              <p>{popupMessage}</p>
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowPopup(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default ViewEvent;
