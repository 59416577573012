import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { jwtDecode } from "jwt-decode";
import API_BASE_URL from "../../ApiConfig/ApiConfig";
import Cookies from "js-cookie";
import './CustomStyles.css';

const ViewTask = () => {

  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dailyTasks, setDailyTasks] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [totalcompletePost, setTotalcompletePost] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalcompleteVideo, setTotalcompleteVideo] = useState(0);
  const [editTask, setEditTask] = useState(null);
  const [formData, setFormData] = useState({
    companyName: "",
    date: "",
    post: "",
    video: "",
    completePost: "",
    completeVideo: "",
    additionalInfo: "",
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [companyNameToDelete, setcompanyNameToDelete] = useState("");
  const [showUnauthorizedPopup, setShowUnauthorizedPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  // Format the selected date for the heading
  const formattedDate = moment(selectedDate).format("DD MMMM YYYY");
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const checkUserRole = () => {
    const token = Cookies.get("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      return decodedToken.role;
    }
    return null;
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/tasks/fetch`)
      .then((response) => {
        if (response.data.success) {
          setTasks(response.data.tasks);
          const tasksForDate = getTasksForDate(selectedDate, response.data.tasks);
          setDailyTasks(tasksForDate);
          updateTotals(tasksForDate);
        } else {
          setError("Failed to fetch tasks.");
        }
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
        setError("Error fetching tasks. Please try again.");
      });
  }, [selectedDate]);

  const updateTotals = (tasks) => {
    const totalPosts = tasks.reduce((sum, task) => sum + (parseInt(task.post) || 0), 0);
    const totalcompletePost = tasks.reduce((sum, task) => sum + (parseInt(task.completePost) || 0), 0);
    const totalVideos = tasks.reduce((sum, task) => sum + (parseInt(task.video) || 0), 0);
    const totalcompleteVideo = tasks.reduce((sum, task) => sum + (parseInt(task.completeVideo) || 0), 0);
    setTotalPosts(totalPosts);
    setTotalcompletePost(totalcompletePost);
    setTotalVideos(totalVideos);
    setTotalcompleteVideo(totalcompleteVideo);
  };

  const getTasksForDate = (date, tasks) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return tasks.filter((task) => moment(task.date).format("YYYY-MM-DD") === formattedDate);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const tasksForDate = getTasksForDate(date, tasks);
    setDailyTasks(tasksForDate);
    updateTotals(tasksForDate);
    setCurrentPage(1); // Reset to the first page whenever the date is changed
  };

  const handleDelete = (task) => {
    const role = checkUserRole();
    if (role === "web" || role === "user") {
      setPopupMessage("You are not authorized to delete this Task.");
      setShowUnauthorizedPopup(true);
    } else {
      setTaskToDelete(task._id);
      setcompanyNameToDelete(task.companyName);
      setShowConfirmModal(true);
    }
  };

  const confirmDelete = () => {
    axios
      .delete(`${API_BASE_URL}/tasks/${taskToDelete}`)
      .then((response) => {
        if (response.data.success) {
          const updatedTasks = tasks.filter((task) => task._id !== taskToDelete);
          setTasks(updatedTasks);
          const tasksForDate = getTasksForDate(selectedDate, updatedTasks);
          setDailyTasks(tasksForDate);
          updateTotals(tasksForDate);
        } else {
          alert("Failed to delete task.");
        }
      })
      .catch((error) => {
        console.error("Error deleting task:", error);
        alert("Error deleting task. Please try again.");
      });
    setShowConfirmModal(false);
  };

  const handleEdit = (task) => {
    const role = checkUserRole();
    if (role === "web" || role === "user") {
      setPopupMessage("You are not authorized to edit this Task.");
      setShowUnauthorizedPopup(true);
    } else {
      setEditTask(task);
      setFormData({
        companyName: task.companyName,
        date: moment(task.date).format("YYYY-MM-DD"),
        post: task.post,
        video: task.video,
        completePost: task.completePost,
        completeVideo: task.completeVideo,
        additionalInfo: task.additionalInfo,
      });
    }
  };

  const handleUpdate = () => {
    axios
      .put(`${API_BASE_URL}/tasks/${editTask._id}`, formData)
      .then((response) => {
        if (response.data.success) {
          const updatedTasks = tasks.map((task) =>
            task._id === editTask._id ? response.data.task : task
          );
          setTasks(updatedTasks);
          const tasksForDate = getTasksForDate(selectedDate, updatedTasks);
          setDailyTasks(tasksForDate);
          updateTotals(tasksForDate);
          setEditTask(null);
        } else {
          alert("Failed to update task.");
        }
      })
      .catch((error) => {
        console.error("Error updating task:", error);
        alert("Error updating task. Please try again.");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const totalPages = Math.ceil(dailyTasks.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const filteredRecords = dailyTasks.slice(startIndex, startIndex + recordsPerPage);




  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (error) return <div className="text-red-500 text-center mb-4">{error}</div>;

  return (
    <div className="min-w-full mx-auto p-6 mt-4 bg-white rounded-2xl shadow-md border border-gray-200" style={{ borderRadius: '30px' }}>
      <h2 className="text-2xl font-bold text-gray-700 mb-2">View Task</h2>
      <hr className="border-t mt-5 border-gray-300 mx-[-24px]" />

      <div className="flex flex-row space-x-4 mt-6 items-start bg-[#F5F5F580] rounded-lg p-4 mb-6">

        <div className="flex bg-[#F5F5F580] justify-center rounded-lg">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            inline
            highlightDates={Array.from(new Set(tasks.map((task) => new Date(moment(task.date).format("YYYY-MM-DD")))))}
            className="w-full max-w-md border border-gray-300 bg-white text-black rounded-lg shadow-lg p-3"
            popperClassName="custom-popper"
            calendarClassName="custom-calendar"
            dayClassName={(date) => (date.getDay() === 0 ? "text-red-500 font-bold" : "text-gray-900")}
          />
        </div>


        <div className="flex-1 rounded-lg p-4 text-center">
          <h2 className="text-xl font-semibold mb-4">Task Summary on {formattedDate}</h2>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-2  border-gray-300">
            <div className="p-2 border font-bold border-gray-300 rounded-md">
              <p className="text-sm font-semibold">Target Posts</p>
              <p className="text-lg text-sky-700 font-bold">{totalPosts}</p>
            </div>
            <div className="p-2 border font-bold border-gray-300 rounded-md">
              <p className="text-sm font-semibold">Complete Posts</p>
              <p className="text-lg text-sky-700 font-bold">{totalcompletePost}</p>
            </div>
            <div className="p-2 border font-bold border-gray-300 rounded-md">
              <p className="text-sm font-semibold">Target Videos</p>
              <p className="text-lg text-sky-700 font-bold">{totalVideos}</p>
            </div>
            <div className="p-2 border font-bold border-gray-300 rounded-md">
              <p className="text-sm font-semibold">Complete Videos</p>
              <p className="text-lg text-sky-700 font-bold">{totalcompleteVideo}</p>
            </div>
          </div>
        </div>


      </div>


      {/* Task Table */}
      <div className="overflow-x-auto">
        {dailyTasks.length > 0 ? (
          <table className="min-w-full bg-white rounded-lg ">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Company Name</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Post</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Cmplt Post</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Video</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Cmplt Video</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Additional Info</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredRecords.map((task) => (
                <tr key={task._id}>
                  <td className="px-4 py-4 text-sm font-medium text-gray-900">{task.companyName}</td>
                  <td className="px-4 py-4 text-sm text-gray-600">{task.post}</td>
                  <td className="px-4 py-4 text-sm text-gray-600">{task.completePost}</td>
                  <td className="px-4 py-4 text-sm text-gray-600">{task.video}</td>
                  <td className="px-4 py-4 text-sm text-gray-600">{task.completeVideo}</td>
                  <td className="px-4 py-4 text-sm text-gray-600 truncate">{task.additionalInfo}</td>
                  <td className="px-4 py-4 space-x-3 text-sm text-gray-600">
                    <button className="text-[#008767] text-xs md:text-sm" onClick={() => handleEdit(task)}>
                      <i className="fas fa-edit"></i>
                    </button>
                    <button className="text-red-500 hover:text-red-700 text-xs sm:text-sm" onClick={() => handleDelete(task)}>
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-gray-500">No tasks available for this date.</p>
        )}
        <div className="flex justify-between items-center mt-4">
          <p className="text-sm text-gray-600">
            Showing {startIndex + 1} to {Math.min(startIndex + recordsPerPage, dailyTasks.length)} of {dailyTasks.length} entries
          </p>
          <div className="flex space-x-1">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="px-3 py-1 bg-gray-200 rounded-md">
              {"<"}
            </button>
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter((page) => {
                return (
                  page === 1 ||
                  page === totalPages ||
                  (page >= currentPage - 1 && page <= currentPage + 1)
                );
              })
              .map((page, index, filteredPages) => (
                <React.Fragment key={page}>
                  {index > 0 && page !== filteredPages[index - 1] + 1 && <span className="px-3 py-1">...</span>}
                  <button
                    onClick={() => handlePageChange(page)}
                    className={`px-3 py-1 rounded-md ${currentPage === page ? "bg-[#006CB5] text-white" : "bg-gray-200"}`}
                  >
                    {page}
                  </button>
                </React.Fragment>
              ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="px-3 py-1 bg-gray-200 rounded-md">
              {">"}
            </button>
          </div>

        </div>
      </div>



      {/* Modal components */}
      {/* Unauthorized Popup */}
      {showUnauthorizedPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
            <h2 className="text-2xl font-bold mb-4">Access Denied</h2>
            <p>{popupMessage}</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowUnauthorizedPopup(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <div className="flex flex-col items-center mb-4">
              <i className="fas fa-trash-alt text-red-500 text-3xl mb-2"></i>
              <h2 className="text-xl font-bold mb-2 text-center">Confirm Delete</h2>
              <p className="text-center">
                Are you sure you want to delete the task for client{" "}
                <span className="font-semibold">{companyNameToDelete}</span>?
              </p>
            </div>
            <div className="flex justify-around mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={() => setShowConfirmModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}


      {/* Edit Task Modal */}
      {editTask && (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-xl p-6 shadow-xl w-full max-w-2xl relative overflow-y-auto max-h-[90vh]">
      <button
        onClick={() => setEditTask(false)}
        className="absolute top-5 right-4 text-gray-600 hover:text-gray-900 border border-gray-300 h-8 w-8 rounded-full flex items-center justify-center transition duration-300"
      >
        ✕
      </button>

      <h2 className="text-2xl font-bold text-gray-800 mb-4">Edit Task Details</h2>
      <hr className="border-t border-gray-300 mb-6 mx-[-24px]" />

      {/* Company Name and Date in one row */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-lg font-medium text-gray-800 mb-2">Company Name</label>
          <input
            readOnly
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
          />
        </div>

        <div>
          <label className="block text-lg font-medium text-gray-800 mb-2">Date</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
          />
        </div>
      </div>

      {/* Post and Complete Post in one row */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-lg font-medium text-gray-800 mb-2">Post</label>
          <input
            type="number"
            name="post"
            value={formData.post}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
          />
        </div>

        <div>
          <label className="block text-lg font-medium text-gray-800 mb-2">Complete Post</label>
          <input
            type="number"
            name="completePost"
            value={formData.completePost}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
          />
        </div>
      </div>

      {/* Video and Complete Video in one row */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-lg font-medium text-gray-800 mb-2">Video</label>
          <input
            type="number"
            name="video"
            value={formData.video}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
          />
        </div>

        <div>
          <label className="block text-lg font-medium text-gray-800 mb-2">Complete Video</label>
          <input
            type="number"
            name="completeVideo"
            value={formData.completeVideo}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
          />
        </div>
      </div>

      {/* Additional Information */}
      <div className="mb-6">
        <label className="block text-lg font-medium text-gray-800 mb-2">Additional Information</label>
        <textarea
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          className="block w-full p-4 border border-gray-300 shadow-sm outline-none focus:ring-1 focus:ring-gray-300 rounded-lg resize-y"
          placeholder="Enter detailed additional information here..."
        />
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          onClick={handleUpdate}
          className="bg-[#006CB5] text-white font-semibold py-2 px-6 rounded-md hover:bg-blue-700 transition duration-300 shadow-lg"
        >
          Save
        </button>
      </div>
    </div>
  </div>
)}


    </div>
  );
};
export default ViewTask;
