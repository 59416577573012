import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarNav from './SidebarNav';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import { jwtDecode } from 'jwt-decode';
import logo from '../../assets/CS24_new_logo_final_white.png';
import defaultImage from './user.png';
import Cookies from 'js-cookie';

const TopNavbar = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [imageExists, setImageExists] = useState(true);
  const [userName, setUserName] = useState('');
  const [userDesignation, setUserDesignation] = useState('');

  const fetchUserProfile = useCallback(async (username) => {
    try {
      const response = await fetch(`${API_BASE_URL}/profileDetails/profile/username/${username}`);
      if (response.ok) {
        const data = await response.json();
        setUserImage(data.profileImage);
        setUserName(data.name);
        setUserDesignation(data.designation);
        checkImageExists(data.profileImage);
      } else {
        console.error('Failed to fetch user profile');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }, []);

  const checkImageExists = async (imagePath) => {
    if (!imagePath) {
      setImageExists(false);
      return;
    }
    try {
      const res = await fetch(`${API_BASE_URL}/${imagePath}`, { method: 'HEAD' });
      setImageExists(res.ok);
    } catch (error) {
      console.error('Error checking image:', error);
      setImageExists(false);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;
      fetchUserProfile(username);
    }
  }, [fetchUserProfile]);

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <>
      <header className="fixed top-0 left-0 w-full p-4 shadow-md lg:px-6 bg-[#006CB5] right-0 h-16 z-20 lg:h-20 lg:bg-white">
        <div className="flex justify-between items-center">
          {/* Toggle Button for Mobile */}
          <button
            onClick={toggleSidebar}
            className="text-white lg:text-gray-600 hover:text-gray-300 lg:hover:text-gray-900 lg:hidden mr-4"
            aria-label="Toggle sidebar"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
              style={{ animation: 'none' }} // Ensure no animation
            >
              <rect y="5" width="25" height="3" fill="white" />
              <rect y="13" width="25" height="3" fill="white" />
              <rect y="21" width="25" height="3" fill="white" />
            </svg>
          </button>


          {/* Mobile Logo */}
          <img
            src={logo}
            alt="mobile logo"
            className="h-9 w-auto lg:hidden transition duration-200 cursor-pointer"
            onClick={handleLogoClick}
          />

          {/* Desktop Logo */}
          <img
            src="/logo.png"
            alt="desktop logo"
            className="h-8 w-auto hidden lg:block lg:h-12 transition duration-200 cursor-pointer"
            onClick={handleLogoClick}
          />

          {/* User Profile */}
          <div className="flex items-center space-x-2 lg:space-x-4">
            <button onClick={handleProfileClick} className="flex items-center space-x-2 text-white lg:text-gray-600">
              {imageExists ? (
                <img
                  src={`${API_BASE_URL}/${userImage}`}
                  alt="User"
                  className="h-10 w-10 rounded-full border-2 border-white lg:border-[#006CB5] object-cover shadow-md"
                />
              ) : (
                <img
                  src={defaultImage}
                  alt="Default User"
                  className="h-10 w-10 rounded-full border-2 border-gray-400 object-cover shadow-md"
                />
              )}

              <div className="hidden lg:flex flex-col text-left">
                <span className="text-[#006CB5] font-semibold">{userName}</span>
                <span className="text-gray-400 text-sm">{userDesignation}</span>
              </div>
            </button>
          </div>
        </div>
      </header>

      {/* Sidebar Overlay for Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 lg:hidden"
          onClick={toggleSidebar}
        >
          <SidebarNav isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </div>
      )}
    </>
  );
};

export default TopNavbar;
