// src/Components/ProtectedRoute/ProtectedRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from 'js-cookie'; // Import the js-cookie library
import {jwtDecode} from 'jwt-decode';

const ProtectedRoute = () => {
  // Retrieve token from cookies
  const token = Cookies.get("token"); 

  // If token does not exist, redirect to login page
  if (!token) {
    return <Navigate to="/" />;
  }

  // Check if token is expired
  try {
    const decodedToken = jwtDecode(token);
    const isExpired = decodedToken.exp * 1000 < Date.now(); // Token expiration check

    console.log('Token Expiration:', decodedToken.exp * 1000, 'Current Time:', Date.now()); // Debug log

    if (isExpired) {
      // If the token has expired, remove it from cookies and redirect to login
      Cookies.remove("token"); 
      return <Navigate to="/" />;
    }
  } catch (error) {
    console.error("Token decoding error:", error);
    Cookies.remove("token"); // Optionally remove invalid token
    return <Navigate to="/" />;
  }

  return <Outlet />; // Render the protected component if the token is valid
};

export default ProtectedRoute;
