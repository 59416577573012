import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import logo from '../../assets/CS24_new_logo_final_white.png';
import Cookies from 'js-cookie';

const SidebarNav = ({ isOpen, toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSocialMediaDropdownOpen, setIsSocialMediaDropdownOpen] = useState(false);
  const [isSoftwareDropdownOpen, setIsSoftwareDropdownOpen] = useState(false);
  const [isEventDropdownOpen, setIsEventDropdownOpen] = useState(false);
  const [isTaskDropdownOpen, setIsTaskDropdownOpen] = useState(false);
  const [isReportDropdownOpen, setIsReportDropdownOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeLink, setActiveLink] = useState('');
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // in seconds

        if (decodedToken.exp < currentTime) {
          // Token is expired, clear the cookie and redirect to login
          Cookies.remove("token");
          navigate("/");
        } else {
          // Token is valid, set user role
          setUserRole(decodedToken.role);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        Cookies.remove("token");
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);
  const handleDropdownClick = (setState, dropdownName) => {
    if (activeDropdown === dropdownName) {
      setActiveDropdown(null);
      setState(false);
    } else {
      setActiveDropdown(dropdownName);
      setIsDropdownOpen(false);
      setIsSocialMediaDropdownOpen(false);
      setIsSoftwareDropdownOpen(false);
      setIsEventDropdownOpen(false);
      setIsTaskDropdownOpen(false);
      setIsReportDropdownOpen(false);
      setState(true);
    }
  };

  const getDropdownHandlers = (setState, dropdownName) => {
    if (isMobileView) {
      return {
        onClick: (event) => {
          event.stopPropagation();
          handleDropdownClick(setState, dropdownName);
        },
      };
    } else {
      return {
        onMouseEnter: () => setState(true),
        onMouseLeave: () => setState(false),
      };
    }
  };

  useEffect(() => {
    if (isMobileView && isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileView, isOpen]);

  const handleLinkClick = (event, link) => {
    if (isMobileView && event.target.tagName !== "BUTTON") {
      toggleSidebar();
    }
    setActiveLink(link);
  };

  const menuItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/34cab2c9f71db5e4bcffc57a043fd911339e5278f7868b9215fef2e23506d3a8?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Client Information",
      dropdown: true,
      state: isDropdownOpen,
      setState: setIsDropdownOpen,
      links: [
        { to: "/clientForm", text: "Add Client", roles: ["web", "admin"] },
        { to: "/ViewClient", text: "View Client", roles: ["social", "web", "admin", "user"] }
      ]
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ed07ad6de10ac398750a90a91c724eab0293801ebc734dd7157742be3c1c8dc8?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Social Media",
      dropdown: true,
      state: isSocialMediaDropdownOpen,
      setState: setIsSocialMediaDropdownOpen,
      links: [
        { to: "/SocialMediaForm", text: "Add Social", roles: ["social", "admin"] },
        { to: "/ViewSocialMedia", text: "View Social", roles: ["social", "web", "admin", "user"] }
      ]
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f8139487fef4c90ca4068b0d76dbb475079c1a1bea50e8d08f0daef419b7151e?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Software",
      dropdown: true,
      state: isSoftwareDropdownOpen,
      setState: setIsSoftwareDropdownOpen,
      links: [
        { to: "/AddSoftware", text: "Add Software", roles: ["social", "admin"] },
        { to: "/ViewSoftware", text: "View Software", roles: ["social", "web", "admin", "user"] }
      ]
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/82aa7e28a1d1d09c117643b12456b6e4baee6ae4ff382a6ac5aba8acd71482cf?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Event",
      dropdown: true,
      state: isEventDropdownOpen,
      setState: setIsEventDropdownOpen,
      links: [
        { to: "/AddEvent", text: "Add Event", roles: ["social", "admin"] },
        { to: "/ViewEvent", text: "View Event", roles: ["social", "web", "admin", "user"] }
      ]
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2f1f525d4dc5773a742680c65973b9b4da73a057ce33d23b644ac3d908d904f7?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Task",
      dropdown: true,
      state: isTaskDropdownOpen,
      setState: setIsTaskDropdownOpen,
      links: [
        { to: "/AddTask", text: "Add Task", roles: ["social", "admin"] },
        { to: "/ViewTask", text: "View Task", roles: ["social", "web", "admin", "user"] }
      ]
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/81e2866bd187a4a6f4a4d92f87403877f1b8bf5433d0055d3abdb6f1712b7e29?apiKey=208ba67bf72b486f9889b172fa1cace6",
      label: "Report",
      dropdown: true,
      state: isReportDropdownOpen,
      setState: setIsReportDropdownOpen,
      links: [
        { to: "/AddReport", text: "Generate Report", roles: ["social", "admin"] },
        { to: "/ViewReport", text: "View Report", roles: ["social", "web", "admin", "user"] }
      ]
    },
  ];

  return (
    <nav
  className={`bg-[#006CB5] top-0 shadow-gray-400 p-6 flex flex-col items-start space-y-4 fixed left-0 h-screen transition-transform duration-600 ease-in-out lg:translate-x-0 lg:w-64 w-50 sm:w-56 hide-scrollbar`}
  style={{ zIndex: 9999 }}
  ref={dropdownRef}
>
  {/* Logo section at the top of the sidebar */}
  <div className="w-full flex mb-6 justify-center items-center">
    <Link to="/dashboard">
      <img src={logo} alt="Logo" className="w-40 h-23" />
    </Link>
  </div>

  {/* Menu Items */}
  {menuItems.map(({ icon, label, dropdown, state, setState, links }) => (
    <div
      key={label}
      className="relative w-full"
      {...(dropdown ? getDropdownHandlers(setState, label) : {})}
    >
      <button
        className={`group flex items-center font-bold py-2 px-4 rounded hover:text-gray-100 transition duration-200 w-full text-left dropdown-button text-[#1FAAE2] relative ${activeLink === label ? "active-label" : ""}`}
        onClick={() => setActiveLink(label)}
      >
        {activeLink === label && (
          <div className="absolute left-0 top-0 h-full w-[4px] bg-white" style={{ transform: "translateX(-50%)" }} />
        )}
        <img
          src={icon}
          alt={label}
          className="w-6 h-6 mr-2 group-hover:scale-110 transition-transform duration-200"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://via.placeholder.com/24"; // Placeholder image
          }}
          style={{ filter: "brightness(0) invert(1)" }}
        />
        {label}
      </button>

      {dropdown && state && (
        <div
          className={`absolute left-full top-0 mt-1 bg-[#FAFBFF] shadow-lg rounded-md py-2 z-50 dropdown-menu w-32 md:w-40 ${isMobileView ? "" : "absolute left-0"}`}
        >
          {links.map(({ to, text, roles }) => (
            <div key={text}>
              <Link
                to={roles.includes(userRole) ? to : "#"}
                className={`block font-medium py-2 px-4 text-gray-700 hover:text-gray-400 ${roles.includes(userRole) ? "text-gray-600" : "text-gray-400 cursor-not-allowed"} ${activeLink === text ? "border-l-4 border-white" : ""}`}
                onClick={(e) => {
                  if (!roles.includes(userRole)) {
                    e.preventDefault();
                  } else {
                    handleLinkClick(e, label);
                  }
                }}
              >
                <span>{text}</span>
              </Link>
              {!roles.includes(userRole) && (
                <span className="block text-red-500 font-bold text-xs mt-1">
                  Your access is limited by CS 24 protocols.
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  ))}

  {/* Admin Login and Logout links at the bottom */}
  <div className="flex-grow" /> {/* This pushes the following content to the bottom */}
  <div className="pt-10">
    <Link
      to="/admin/login"
      className="flex items-center font-bold py-2 px-4 rounded hover:text-gray-100 transition duration-200 w-full text-left text-[#1FAAE2]"
      onClick={toggleSidebar}
    >
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/636567b0b4c7df333ae28911987757683a314d9591a4fcaf26d9bbe64090181d?apiKey=208ba67bf72b486f9889b172fa1cace6"
        alt="Admin Login"
        className="w-6 h-6 mr-2"
        style={{ filter: "brightness(0) invert(1)" }}
      />
      <span>Admin Login</span>
    </Link>

    <Link
      to="/logout"
      className="flex items-center font-bold pt-6 text-[#1FAAE2] py-2 px-4 rounded hover:text-gray-100 transition duration-200 w-full text-left"
      onClick={toggleSidebar}
    >
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/447eb24ec265a3d93c378652e93d62d53da27c2ed079220beb15f4af6a173258?apiKey=208ba67bf72b486f9889b172fa1cace6"
        alt="Log Out"
        className="w-6 h-6 mr-2"
        style={{ filter: "brightness(0) invert(1)" }}
      />
      <span>Log Out</span>
    </Link>
  </div>
</nav>

  );
};

export default SidebarNav;
