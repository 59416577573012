import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import API_BASE_URL from '../../ApiConfig/ApiConfig';


const Reset = () => {
  const { token } = useParams(); // Get the token from the URL
  const navigate = useNavigate(); // Initialize navigate
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false); // State to manage success message

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword }), // Ensure token is included here
      });

      if (!response.ok) {
        const errorData = await response.text(); // Log for debugging
        console.error('Error response:', errorData);
        try {
          const data = JSON.parse(errorData);
          setError(data.message || 'Failed to reset password.');
        } catch (err) {
          setError('Failed to reset password. Server responded with HTML.');
        }
        return;
      }

      setSuccess(true);
      // Show alert and navigate after OK
      if (window.confirm('Password has been reset successfully. Click OK to go to login page.')) {
        navigate('/admin/login');
      }
      
    } catch (err) {
      console.error('Error:', err); // Log the error for debugging
      setError('An unexpected error occurred.');
    }
  };
  
  return (
    <div className="max-w-md mx-auto mt-40 p-6 rounded-lg shadow-md bg-white">
      <h2 className="text-2xl text-center mb-4 text-gray-800">Reset Password</h2>
      {error && <p className="text-red-500 text-center">{error}</p>}
      {success && <p className="text-green-500 text-center">Password reset successfully!</p>}
      <form onSubmit={handleResetPassword} className="flex flex-col">
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          className="input mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          className="input mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button type="submit" className="login-button py-2 bg-gradient-to-r from-blue-500 to-teal-500 text-white font-semibold rounded-lg hover:scale-105 transition-transform duration-200">
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default Reset;
