import React from 'react';
import './Loader.css'; // Import the CSS file for loader

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <svg viewBox="25 25 50 50">
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
      </div>
    </div>
  );
};

export default Loader;
