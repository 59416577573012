import React, { useState, useEffect } from "react";
import axios from "axios";
import ReportModal from "./ReportModal"; // Ensure correct path
import UpdateReport from "./UpdateReport"; // Ensure correct path
import { jwtDecode } from "jwt-decode"; // Import jwt-decode
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import Cookies from 'js-cookie';
const ViewReport = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [years, setYears] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State to handle popup visibility
  const [popupMessage, setPopupMessage] = useState(""); // State to handle popup message
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const months = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/SMMR/reports`)
      .then((response) => {
        const data = response.data;
        setReports(data);

        // Extract unique years from the report data
        const uniqueYears = Array.from(
          new Set(
            data.map((report) => new Date(report.reportDate).getFullYear())
          )
        );
        setYears(uniqueYears);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching reports:", error);
        setError("Error fetching reports");
        setLoading(false);
      });
  }, []);
  // Function to check the user's role
  const checkUserRole = () => {
    const token = Cookies.get("token"); // Retrieve token from Cookies
    if (token) {
      const decodedToken = jwtDecode(token); // Decode the token to get the user's role
      const { role } = decodedToken;
      return role;
    }
    return null;
  };
  const filterReports = () => {



    let filteredReports = reports.filter((report) => {
      const reportDate = new Date(report.reportDate);
      const yearMatches = !selectedYear || reportDate.getFullYear() === parseInt(selectedYear);
      const monthMatches = !selectedMonth || reportDate.getMonth() + 1 === parseInt(selectedMonth);
      const nameMatches = !searchQuery || report.companyName.toLowerCase().includes(searchQuery.toLowerCase());

      return yearMatches && monthMatches && nameMatches;
    });



    // Sort the reports by companyName (case-insensitive)
    filteredReports.sort((a, b) => {
      if (!a.companyName || !b.companyName) return 0; // Handle cases where companyName might be missing
      return a.companyName.localeCompare(b.companyName);
    });



    return filteredReports;
  };


  const handleViewPersonalReport = (report) => {
    setSelectedReport(report);
    setShowModal(true);
    setShowUpdate(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReport(null);
    setShowUpdate(false);
  };

  const handleUpdate = (report) => {
    const role = checkUserRole();
    if (role === "web" || role === "user") {
      setPopupMessage("You are not authorized to edit this Report.");
      setShowPopup(true); // Show popup when unauthorized
    } else {
      setSelectedReport(report);
      setShowUpdate(true);
      setShowModal(false);
    }
  };

  const handleSaveUpdate = (updatedReport) => {
    axios
      .put(
        `${API_BASE_URL}/SMMR/reports/${selectedReport._id}`,
        updatedReport
      )
      .then((response) => {
        setReports(
          reports.map((report) =>
            report._id === selectedReport._id ? response.data : report
          )
        );
        setShowUpdate(false);
        setSelectedReport(null);
      })
      .catch((error) => {
        console.error("Error updating report:", error);
      });
  };
  const handleDelete = (report) => {
    const role = checkUserRole();
    if (role === "web" || role === "user") {
      setPopupMessage("You are not authorized to delete this Report.");
      setShowPopup(true); // Show popup when unauthorized
    } else {
      // सेट करें कि किस रिपोर्ट को डिलीट करना है
      setReportToDelete(report);
      setShowDeleteModal(true); // मोडल दिखाएं
    }
  };

  // डिलीट पुष्टि
  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/SMMR/reports/${reportToDelete._id}`);
      setReports(reports.filter((r) => r._id !== reportToDelete._id));
      setShowDeleteModal(false); // मोडल बंद करें
    } catch (error) {
      console.error("Error deleting report:", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const filteredReports = filterReports();

  return (

    // <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
    <div className="min-w-full mx-auto md:mt-4 mt-10 p-6 bg-white rounded-3xl shadow-2xl" style={{ maxWidth: '900px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 20px' }}>
  <h2 className="text-2xl font-bold text-gray-700 mb-4">View Monthly Summary</h2>
  <hr className="border-t mb-6 border-gray-300 mx-[-24px]" />

  <div className="mb-6 flex flex-wrap gap-4">
    {/* Company Name Input */}
    <div className="flex-grow">
      <label className="block text-lg font-medium text-gray-700 mb-2">Company Name</label>
      <input
        type="text"
        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
        placeholder="Enter Company"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>

    {/* All Years Dropdown */}
    <div className="flex-grow">
      <label className="block text-lg font-medium text-gray-700 mb-2">All Years</label>
      <select
        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.target.value)}
      >
        <option value="">Select Year</option>
        {years.map((year) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
    </div>

    {/* All Months Dropdown */}
    <div className="flex-grow">
      <label className="block text-lg font-medium text-gray-700 mb-2">All Months</label>
      <select
        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300"
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
      >
        <option value="">Select Month</option>
        {months.map((month) => (
          <option key={month.value} value={month.value}>{month.name}</option>
        ))}
      </select>
    </div>
  </div>

  {/* Display Report Table */}
  {filteredReports.length === 0 ? (
    <p className="text-center text-gray-500">No reports available</p>
  ) : (
    <>
      <table className="w-full bg-gray-200 rounded-lg overflow-hidden">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-6 py-3 text-left text-[15px] font-semibold text-gray-500">Company Name</th>
            <th className="px-6 py-3 text-left text-[15px] font-semibold text-gray-500">Week 1</th>
            <th className="px-6 py-3 text-left text-[15px] font-semibold text-gray-500">Week 2</th>
            <th className="px-6 py-3 text-left text-[15px] font-semibold text-gray-500">Week 3</th>
            <th className="px-6 py-3 text-left text-[15px] font-semibold text-gray-500">Week 4</th>
            <th className="px-6 py-3 text-left text-[15px] font-semibold text-gray-500">Banner</th>
            <th className="px-6 py-3 text-left text-[15px] font-semibold text-gray-500">Actions</th>
          </tr>
        </thead>
        
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredReports.map((report) => (
            <tr key={report._id} className="hover:bg-gray-50">
              <td className="px-6 py-4 text-sm font-medium text-gray-900">{report.companyName}</td>
              <td className="px-6 py-4 text-sm text-gray-500">05</td>
              <td className="px-6 py-4 text-sm text-gray-500">01</td>
              <td className="px-6 py-4 text-sm text-gray-500">05</td>
              <td className="px-6 py-4 text-sm text-gray-500">02</td>
              <td className="px-6 py-4 text-sm text-gray-500">05</td>
              <td className="px-6 py-4 text-sm">
                <div className="flex space-x-3">
                  <button className="text-blue-500 hover:text-blue-600" onClick={() => handleViewPersonalReport(report)}>
                    <i className="fas fa-eye text-sm"></i>
                  </button>
                  <button className="text-[#008767] hover:text-green-600" onClick={() => handleUpdate(report)}>
                    <i className="fas fa-edit text-sm"></i>
                  </button>
                  <button className="text-red-500 hover:text-red-600" onClick={() => handleDelete(report)}>
                    <i className="fas fa-trash-alt text-sm"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Report Modal */}
      {showModal && selectedReport && (
        <ReportModal report={selectedReport} onClose={handleCloseModal} />
      )}

      {/* Update Report Modal */}
      {showUpdate && selectedReport && (
        <UpdateReport report={selectedReport} onSave={handleSaveUpdate} onClose={handleCloseModal} />
      )}
    </>
  )}


      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <div className="flex flex-col items-center mb-4">
              <i className="fas fa-trash-alt text-red-500 text-4xl mb-3"></i>
              <h2 className="text-xl font-bold mb-3 text-center">Are You Sure for Delete?</h2>
              <p className="text-center">
                Are you sure you want to delete this report?
              </p>
            </div>
            <div className="flex justify-around mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Access Denied Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Access Denied</h2>
            <p>{popupMessage}</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowPopup(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewReport;
