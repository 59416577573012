import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import Cookies from 'js-cookie';
import { FaSearch, FaChevronRight } from 'react-icons/fa';
import GoogleLogo from '../Dashboard-Con/logos/google 1.svg'; // Adjust the path as needed
import LinkedInLogo from '../Dashboard-Con/logos/linkedin (1) 1.svg';
import InstagramLogo from '../Dashboard-Con/logos/instagram.svg';
import FacebookLogo from '../Dashboard-Con/logos/facebook 1.svg';

const ViewSocialMedia = () => {
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showAuthMessage, setShowAuthMessage] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    google: { userId: '', password: '', showPassword: false },
    linkedin: { userId: '', password: '', showPassword: false },
    instagram: { userId: '', password: '', showPassword: false },
    facebook: { userId: '', password: '', showPassword: false },
  });
  const [userRole, setUserRole] = useState(null);

  const platformLogos = {
    google: <img src={GoogleLogo} alt="Google logo" className="h-8 w-8 mr-2" />,
    linkedin: <img src={LinkedInLogo} alt="LinkedIn logo" className="h-8 w-8 mr-2" />,
    instagram: <img src={InstagramLogo} alt="Instagram logo" className="h-8 w-8 mr-2" />,
    facebook: <img src={FacebookLogo} alt="Facebook logo" className="h-8 w-8 mr-2" />,
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, []);

  useEffect(() => {
    const fetchSocialMediaData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/social-media-form`);
        setSocialMediaData(response.data);
      } catch (error) {
        console.error('Error fetching social media data:', error);
      }
    };
    fetchSocialMediaData();
  }, []);

  const handleViewClick = (clientData) => {
    if (userRole === 'user' || userRole === 'web') {
      setShowAuthMessage(true);
    } else {
      setSelectedClient(clientData);
      setShowModal(true);
    }
  };

  const handleUpdateClick = (clientData) => {
    if (userRole === 'user' || userRole === 'web') {
      setShowAuthMessage(true);
    } else {
      setSelectedClient(clientData);
      setFormData({
        companyName: clientData.companyName || '',
        google: { ...clientData.google, showPassword: false },
        linkedin: { ...clientData.linkedin, showPassword: false },
        instagram: { ...clientData.instagram, showPassword: false },
        facebook: { ...clientData.facebook, showPassword: false },
      });
      setShowUpdateModal(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePlatformChange = (platform, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [platform]: {
        ...prevData[platform],
        [field]: value,
      },
    }));
  };

  const togglePasswordVisibility = (platform) => {
    setFormData((prevData) => ({
      ...prevData,
      [platform]: {
        ...prevData[platform],
        showPassword: !prevData[platform].showPassword,
      },
    }));
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (!selectedClient || !selectedClient._id) {
      console.error('Selected client is not set or missing ID');
      return;
    }
    try {
      await axios.put(`${API_BASE_URL}/api/social-media-form/${selectedClient._id}`, formData);
      const updatedData = await axios.get(`${API_BASE_URL}/api/social-media-form`);
      setSocialMediaData(updatedData.data);
      setShowUpdateModal(false);
      window.alert('Social media data updated successfully!');
    } catch (error) {
      console.error('Error updating social media data:', error);
      window.alert('Failed to update social media data.');
    }
  };

  const handleDeleteClick = (clientData) => {
    if (userRole === 'user' || userRole === 'web') {
      setShowAuthMessage(true);
    } else {
      setSelectedClient(clientData);
      setShowDeleteConfirmModal(true);
    }
  };

  const confirmDeleteClient = async () => {
    if (!selectedClient || !selectedClient._id) return;
    try {
      await axios.delete(`${API_BASE_URL}/api/social-media-form/${selectedClient._id}`);
      const updatedData = await axios.get(`${API_BASE_URL}/api/social-media-form`);
      setSocialMediaData(updatedData.data);
      setShowDeleteConfirmModal(false);
      setSelectedClient(null);
    } catch (error) {
      console.error('Error deleting client data:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedClient(null);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setFormData({
      companyName: '',
      google: { userId: '', password: '', showPassword: false },
      linkedin: { userId: '', password: '', showPassword: false },
      instagram: { userId: '', password: '', showPassword: false },
      facebook: { userId: '', password: '', showPassword: false },
    });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteConfirmModal(false);
    setSelectedClient(null);
  };

  const handleCloseAuthMessage = () => {
    setShowAuthMessage(false);
  };
  const [searchTerm, setSearchTerm] = useState('');

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtered data based on search term
  const filteredData = socialMediaData.filter((clientData) =>
    clientData.companyName.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10; // Set to whatever number you want for each page
  const totalPages = Math.ceil(filteredData.length / recordsPerPage); // Paginate filtered data

  // Derived data for pagination
  const startIndex = (currentPage - 1) * recordsPerPage;
  const currentRecords = filteredData.slice(startIndex, startIndex + recordsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="min-w-full mx-auto p-6 mt-4 bg-white rounded-2xl shadow-md  " style={{ borderRadius: '30px' }}>
      <div className="flex justify-between items-center pt-2 mb-4">
        <h1 className="text-2xl font-bold text-gray-700 mb-2">Social Media Credentials</h1>


        {/* Search bar */}
        <div className="relative mr-6 w-64">
          <input
            type="text"
            placeholder="Search Company"
            value={searchTerm}
            style={{ animation: 'none' }}
            onChange={handleSearchChange}
            className="border p-2 pl-10 pr-10 rounded-full w-full shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
          />
          {/* Left Icon */}
          <span className="absolute left-4 top-4 text-gray-400" style={{ animation: 'none', transition: 'none' }}>
            <FaSearch />
          </span>

          {/* Right Icon */}

        </div>
      </div>

      <hr className="border-t border-gray-300 mx-[-24px] " />
      <div className="overflow-x-auto ">
        <table className="w-full bg-white rounded-md border-collapse">
          <thead className="text-gray-500">
            <tr className="-mx-6 text-[#B5B7C0] border-gray-300">
              <th className="p-4 text-left text-sm font-semibold text-[#B5B7C0] border-r border-gray-300">Company Name</th>
              <th className="p-4 text-centertext-sm font-semibold text-[#B5B7C0] border-r border-gray-300 w-1/6">View</th>
              <th className="p-4 text-centertext-sm font-semibold text-[#B5B7C0] border-r border-gray-300 w-1/6">Edit</th>
              <th className="p-4 text-centertext-sm font-semibold text-[#B5B7C0]  w-1/6">Delete</th>
            </tr>
            <tr>
              <td colSpan="4" className="p-0">
                <hr className="border-t border-gray-300 w-full" />
              </td>
            </tr>
          </thead>






          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((clientData) => (
                <tr key={clientData._id}>
                  <td className="p-4 text-left text-[#4c5258] text-sm sm:text-base font-semibold border-r border-gray-300">{clientData.companyName}</td>
                  <td className="p-4 text-center border-r border-gray-300">
                    <span
                      onClick={() => handleViewClick(clientData)}
                      className={`text-xs font-bold sm:text-sm cursor-pointer ${userRole === 'user' || userRole === 'web'
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-[#006CB5] '
                        }`}
                    >
                      View
                    </span>
                  </td>

                  <td className="p-4 text-center border-r border-gray-300">
                    <span
                      onClick={() => handleUpdateClick(clientData)}
                      className={`text-xs font-bold sm:text-sm cursor-pointer ${userRole === 'user' || userRole === 'web'
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-[#006CB5]'
                        }`}
                      style={{ pointerEvents: userRole === 'user' || userRole === 'web' ? 'none' : 'auto' }}
                    >
                      Edit
                    </span>
                  </td>

                  <td className="p-4 text-center">
                    <span
                      onClick={() => handleDeleteClick(clientData)}
                      className={`flex items-center justify-center font-bold text-xs sm:text-sm cursor-pointer ${userRole === 'user' || userRole === 'web'
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-red-500 hover:text-red-600'
                        }`}
                      style={{ pointerEvents: userRole === 'user' || userRole === 'web' ? 'none' : 'auto' }}
                    >
                      <i className={`fas fa-trash-alt text-lg mr-1 ${userRole === 'user' || userRole === 'web' ? 'text-gray-400' : 'text-red-500'}`}></i>

                    </span>
                  </td>


                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="p-4 text-center text-sm sm:text-base">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-2">
          <p className="text-xs text-gray-600">
            Showing {startIndex + 1} to {Math.min(startIndex + recordsPerPage, filteredData.length)} of{" "}
            {filteredData.length} entries
          </p>
          <div className="flex space-x-2">
            {/* Previous button */}
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-2 py-0.5 bg-gray-200 rounded-sm"
            >
              {"<"}
            </button>

            {/* Page numbers with ellipsis */}
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter((page) => {
                return (
                  page === 1 || // Always show the first page
                  page === totalPages || // Always show the last page
                  (page <= 3 || (page >= currentPage - 1 && page <= currentPage + 1)) // Show pages around current page
                );
              })
              .map((page, index, filteredPages) => (
                <React.Fragment key={page}>
                  {index > 0 && page !== filteredPages[index - 1] + 1 && (
                    <span className="px-1">...</span> // Show ellipsis for non-consecutive pages
                  )}
                  <button
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-0.5 rounded-sm ${currentPage === page ? "bg-[#006CB5] text-white" : "bg-gray-200"
                      }`}
                  >
                    {page}
                  </button>
                </React.Fragment>
              ))}

            {/* Next button */}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-2 py-0.5 bg-gray-200 rounded-sm"
            >
              {">"}
            </button>
          </div>
        </div>


      </div>

      {/* Update Modal */}
      {showUpdateModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="w-[1032px] mt-4 mx-auto p-8 bg-white rounded-xl shadow-lg">
            <div className="flex justify-between items-center mb-2">
              <h1 className="text-2xl font-bold text-gray-700">Edit Social Media</h1>
              <button
                onClick={handleCloseUpdateModal}
                className="flex items-center justify-center w-8 h-8 border-2 border-gray-500 rounded-full text-gray-500 hover:bg-gray-200"
                aria-label="Close"
              >
                <i className="fas fa-times text-xl"></i>
              </button>
            </div>

            <div className="-mx-8">
              <hr className="border-gray-300 mb-8" />
            </div>

            <form
              onSubmit={handleUpdateSubmit}
              className="rounded-lg  p-4 scrollable-modal" // Added scrollable-modal class here
            >
              <div className="mb-4">
                <label
                  className="font-poppins text-16px font-bold leading-6 tracking-tight text-left mb-2 block"
                  htmlFor="client-name"
                >
                  CompanyName
                </label>
                <input
                  readOnly
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 appearance-none pr-10"
                />
              </div>

              {['google', 'linkedin', 'instagram', 'facebook'].map((platform) => (
                <div key={platform} className="mb-8 mt-8">
                  <div className="flex items-center mb-2">
                    {/* Logo for the platform */}
                    <div className="flex items-center">
                      {platformLogos[platform]}
                    </div>
                    <h3 className="text-lg font-semibold ml-2 capitalize text-sm sm:text-lg">{platform} Credentials:</h3>
                  </div>

                  <div className="flex flex-row mt-5 gap-4">
                    <div className="w-full sm:w-1/2">
                      <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">User ID</label>
                      <input
                        type="text"
                        name={`${platform}-userId`}
                        value={formData[platform].userId}
                        onChange={(e) => handlePlatformChange(platform, 'userId', e.target.value)}
                        className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                      />
                    </div>
                    <div className="w-full sm:w-1/2">
                      <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">Password</label>
                      <div className="relative">
                        <input
                          type={formData[platform].showPassword ? "text" : "password"}
                          name={`${platform}-password`}
                          value={formData[platform].password}
                          onChange={(e) => handlePlatformChange(platform, 'password', e.target.value)}
                          className="max-w-md w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                        />
                        <button
                          type="button"
                          onClick={() => togglePasswordVisibility(platform)}
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                        >
                          {formData[platform].showPassword ? 'Hide' : 'Show'}
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              ))}
            </form>
            <div className="flex justify-end space-x-2 mr-6 sm:space-x-4 mt-4">
              <button
                type="submit"
                className="bg-[#006CB5] text-white px-4 py-2 rounded-lg text-sm sm:text-base hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}


      {showModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-md sm:max-w-2xl transition-transform transform scale-100">

            <div className="flex justify-between items-center mb-6 ">
              <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">
                Client Profile
              </h2>
              <button
                onClick={handleCloseModal}
                className="flex items-center justify-center w-8 h-8 border-2 border-gray-500 rounded-full text-gray-500 hover:bg-gray-200"
                aria-label="Close"
              >
                <i className="fas fa-times text-xl"></i> {/* Close Icon */}
              </button>
            </div>

            <hr className="border-t border-gray-300 mx-[-30px] mb-4" />

            <div className="ml-6 overflow-y-auto max-h-[70vh]"> {/* Add max height and scrolling */}
              <div className="mb-6">
                <div className="flex items-center">
                  <h3 className="text-base sm:text-lg font-bold text-[#006CB5] mr-2">
                    Company Name:
                  </h3>
                  <p className="text-sm sm:text-base text-gray-600">
                    {selectedClient.companyName}
                  </p>
                </div>
              </div>

              {['google', 'linkedin', 'instagram', 'facebook'].map((platform) => {
                const capitalizedPlatform = platform.charAt(0).toUpperCase() + platform.slice(1);
                return (
                  <div className="mb-8" key={platform}>
                    <h3 className="text-base sm:text-lg font-bold text-gray-700">
                      {capitalizedPlatform} Credentials:
                    </h3>
                    <div className="mt-4 text-sm sm:text-base text-gray-600 flex flex-col sm:flex-row">
                      <p className="sm:mr-4 flex-1">
                        <span className="font-medium text-[#006CB5]">{capitalizedPlatform} User ID:</span> {selectedClient[platform]?.userId || "Not Provided"}
                      </p>
                      <p className="flex-1 sm:ml-4">
                        <span className="font-medium text-[#006CB5]">{capitalizedPlatform} Password:</span> {selectedClient[platform]?.password || "Not Provided"}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}


      {/* Delete Confirmation Modal */}
      {showDeleteConfirmModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-white p-5 sm:p-6 rounded-lg shadow-lg w-full max-w-xs sm:max-w-md">
            <div className="flex justify-center mb-4">
              <i className="fas fa-trash-alt text-red-500 text-4xl"></i> {/* Increased icon size */}
            </div>
            <h2 className="text-lg sm:text-xl pt-3 font-bold text-gray-800 mb-1 text-center">Are You Sure for Delete?</h2>
            <p className="text-sm sm:text-base pt-5 text-gray-600 text-center mb-4">
              Do you really want to delete this client record?
            </p>
            <div className="flex justify-center mt-7 space-x-12"> {/* Centering the buttons with space between */}
              <button
                onClick={handleCloseDeleteModal}
                className="bg-[#ECECEC] text-black font-bold px-10 py-2 rounded-md text-sm sm:text-base hover:bg-gray-600 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteClient}
                className="bg-red-500 font-bold text-white px-10 py-2 rounded-md text-sm sm:text-base hover:bg-red-600 transition duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}


      {/* Authorization Message Modal */}
      {showAuthMessage && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-full sm:max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4 text-sm sm:text-2xl">Unauthorized</h2>
            <p className="text-sm sm:text-base">You do not have permission to perform this action.</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleCloseAuthMessage}
                className="bg-gray-500 text-white px-2 py-1 rounded text-xs sm:text-sm hover:bg-gray-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSocialMedia;
