import React, { useState } from 'react';
import API_BASE_URL from '../../ApiConfig/ApiConfig';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const SoftwareFormScreen = () => {
  const [softwareName, setSoftwareName] = useState('');
  const [amount, setAmount] = useState('');
  const [purchaseDate, setPurchaseDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [formErrors, setFormErrors] = useState({
    softwareName: false,
    amount: false,
    purchaseDate: false,
    expiryDate: false,
    userId: false,
    password: false,
  });

  const handleSubmit = async () => {
    const errors = {
      softwareName: !softwareName,
      amount: !amount,
      purchaseDate: !purchaseDate,
      expiryDate: !expiryDate,
      userId: !userId,
      password: !password,
    };

    if (Object.values(errors).some(error => error)) {
      setFormErrors(errors);
      return;
    }

    const software = {
      softwareName,
      amount,
      purchaseDate,
      expiryDate,
      userId,
      password,
      additionalInfo,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/software/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(software),
      });

      if (response.ok) {
        alert('Software added successfully!');
        // Clear form fields
        setSoftwareName('');
        setAmount('');
        setPurchaseDate('');
        setExpiryDate('');
        setUserId('');
        setPassword('');
        setAdditionalInfo('');
        setFormErrors({
          softwareName: false,
          amount: false,
          purchaseDate: false,
          expiryDate: false,
          userId: false,
          password: false,
        });
      } else {
        alert('Error adding software. Please try again.');
      }
    } catch (error) {
      alert('Error adding software. Please try again.');
    }
  };

  return (
    <div className="min-w-full mx-auto p-6 mt-4 bg-white rounded-2xl shadow-md" style={{ borderRadius: '30px' }}>
    <h2 className="text-2xl font-bold text-gray-700 mb-2">Our Software</h2>
    <hr className="border-t border-gray-300 mx-[-24px] mb-6" />
    
    <div
      className="p-4 rounded-md"
     
    >
      {/* Responsive grid container for input fields */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Software Name</label>
          <input
            type="text"
            value={softwareName}
            onChange={(e) => setSoftwareName(e.target.value)}
            placeholder="Enter name"
            className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 ${formErrors.softwareName ? 'border-red-500' : 'border-gray-300'}`}
          />
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Software Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 ${formErrors.amount ? 'border-red-500' : 'border-gray-300'}`}
          />
        </div>
      </div>
  
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Date of Purchase</label>
          <input
            type="date"
            value={purchaseDate}
            onChange={(e) => setPurchaseDate(e.target.value)}
            className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 ${formErrors.purchaseDate ? 'border-red-500' : 'border-gray-300'}`}
          />
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Date of Expiry</label>
          <input
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 ${formErrors.expiryDate ? 'border-red-500' : 'border-gray-300'}`}
          />
        </div>
      </div>
  
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Software User ID</label>
          <input
            type="text"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="Enter ID"
            className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 ${formErrors.userId ? 'border-red-500' : 'border-gray-300'}`}
          />
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Software Password</label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className={`w-full p-2 pr-10 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 ${formErrors.password ? 'border-red-500' : 'border-gray-300'}`}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
            >
              {showPassword ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
            </button>
          </div>
        </div>
      </div>
  
      <label className="block text-sm font-medium text-gray-700 mb-1">Additional Info</label>
      <textarea
        value={additionalInfo}
        onChange={(e) => setAdditionalInfo(e.target.value)}
        placeholder="Enter additional information"
        rows="4"
        className="w-full p-3 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
      />
  
      
    </div>
    <div className="flex justify-end mt-4 mr-4">
        <button
          onClick={handleSubmit}
          className="bg-[#006CB5] text-white font-semibold py-2 px-8 rounded-[8px] transition duration-300 shadow-lg w-full sm:w-auto"
        >
          Submit
        </button>
      </div>
  </div>
  
  );
};

export default SoftwareFormScreen;
