import React, { useState, useEffect } from 'react';

const UpdateReport = ({ report, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        companyName: '',
        week1Post: '',
        week1Video: '',
        week2Post: '',
        week2Video: '',
        week3Post: '',
        week3Video: '',
        week4Post: '',
        week4Video: '',
        monthlyBanner: '',
    });

    useEffect(() => {
        if (report) {
            setFormData({
                companyName: report.companyName || '',
                week1Post: report.week1?.post || '',
                week1Video: report.week1?.video || '',
                week2Post: report.week2?.post || '',
                week2Video: report.week2?.video || '',
                week3Post: report.week3?.post || '',
                week3Video: report.week3?.video || '',
                week4Post: report.week4?.post || '',
                week4Video: report.week4?.video || '',
                monthlyBanner: report.monthlyBanner || '',
            });
        }
    }, [report]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Create the reportData object with current date
        const reportData = {
            companyName: formData.companyName,
            week1: {
                post: formData.week1Post,
                video: formData.week1Video
            },
            week2: {
                post: formData.week2Post,
                video: formData.week2Video
            },
            week3: {
                post: formData.week3Post,
                video: formData.week3Video
            },
            week4: {
                post: formData.week4Post,
                video: formData.week4Video
            },
            monthlyBanner: formData.monthlyBanner,
            reportDate: new Date().toISOString() // Add the current date
        };

        onSave(reportData); // Call the onSave function with updated reportData
    };

    return (


        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center z-50">
            <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 lg:p-8  w-full max-w-lg sm:max-w-xl lg:max-w-2xl mx-auto max-h-[80vh] overflow-y-auto mt-14">
                <div className="flex justify-between items-center mb-2">
                    <h2 className="text-2xl font-bold mb-2">Update Report</h2>
                    <button
                        onClick={onClose}
                        className="flex items-center justify-center w-8 h-8 border-2 border-gray-500 rounded-full text-gray-500 hover:bg-gray-200"
                        aria-label="Close"
                    >
                        <i className="fas fa-times text-xl"></i>
                    </button>
                </div>
                <hr className="border-t mt-5 border-gray-300 mx-[-30px]" />
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Client Name */}
                    <div className="mb-4 mt-6">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Client Name</label>
                        <input
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            className="font-semibold w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                            required
                        />
                    </div>

                    {/* Weekly Reports */}
                    {[1, 3].map((startWeek) => (
                        <div key={startWeek} className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6">
                            {[startWeek, startWeek + 1].map((week) => (
                                <div key={week}>
                                    <h3 className="text-xl font-semibold text-sky-700 mb-2">Week {week}</h3>
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">Post</label>
                                            <input
                                                type="text"
                                                name={`week${week}Post`}
                                                value={formData[`week${week}Post`]}
                                                onChange={handleChange}
                                                className="font-semibold w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">Video</label>
                                            <input
                                                type="text"
                                                name={`week${week}Video`}
                                                value={formData[`week${week}Video`]}
                                                onChange={handleChange}
                                                className="font-semibold w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}

                    {/* Monthly Banner */}
                    <div className="mt-8">
                        <h3 className="text-xl font-semibold text-gray-700 mb-4">Monthly Banner</h3>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Banner</label>
                        <input
                            type="text"
                            name="monthlyBanner"
                            value={formData.monthlyBanner}
                            onChange={handleChange}
                            className="font-semibold w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                        />
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-sky-700 p-4 text-white  px-4 py-2 rounded w-full sm:w-auto"
                        >
                            Save
                        </button>
                    </div>
                </form>

            </div>
        </div>

    );
};

export default UpdateReport;
