import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
import logo from './CS24.png'; // Add the path to your logo
import illustration from '../../assets/katman_1.png'; // Add your illustration image
import Cookies from 'js-cookie';
const Login = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(''); // Clear previous errors



    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        // Use Cookies.set() to store the token securely
        Cookies.set('token', token, {
          expires: 7, // Token will expire in 7 days
          secure: true, // Use 'secure' to ensure it's only sent over HTTPS
          sameSite: 'Strict' // Prevent CSRF
        });


        navigate('/dashboard');
      } else {
        const data = await response.json();
        setError(data.message);
      }
    } catch (error) {
      setError('An unexpected error occurred.');
    } finally {
      // Reset loading state after the request completes
    }
  };

  return (
    // Outer container that is full screen and centers the content inside
    <div className="w-full h-screen flex items-center justify-center bg-[#FAFBFF]">
      {/* Inner container (login box) */}
      <div className="flex flex-col md:flex-row w-[90%] md:w-[900px] h-auto md:h-[500px] bg-[#FFFFFF] shadow-lg rounded-3xl overflow-hidden mb-10 md:mb-0 md:mt-6">
      {/* Left side with the illustration for mobile */}
        <div className="block md:hidden w-full flex items-center justify-center mb-2">
          <img src={illustration} alt="Illustration" className="max-w-[70%] h-auto" /> {/* Further reduced image size */}
        </div>

        {/* Left side with the illustration for larger screens */}
        <div className="hidden md:flex w-1/2 bg-[#AFDBF1] items-center justify-center">
          <img src={illustration} alt="Illustration" className="max-w-full h-auto" />
        </div>

        {/* Right side with the form */}
        <div className="w-full max-w-sm sm:ml-8 flex flex-col justify-center  items-center ">
          {/* Show logo only on larger screens */}
          <div className="hidden md:block mb-4">
            <img src={logo} alt="CreativeStudio24" style={{ width: '220px' }} /> {/* Set size in pixels */}
          </div>

          <h1 className="mb-2 text-lg font-bold text-gray-700 text-center">Welcome to CreativeStudio24</h1>
          <p className="mb-3 text-[#B5B7C0] text-xs text-center">Enter your username to continue</p>

          <form onSubmit={handleSubmit} className="w-full flex flex-col ml-8 sm:ml-16">

            <label htmlFor="username" className="block text-[14px] font-medium text-gray-700 mb-2">
              Username
            </label>
            <input
              required
              className="w-full max-w-[90%] sm:max-w-xs px-3 py-2 mb-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
              type="text"
              name="username"
              placeholder="Enter your username"
              value={formData.username}
              onChange={handleChange}
            />

            <label htmlFor="password" className="block text-[14px] font-medium text-gray-700 mb-2">
              Password
            </label>
            <div className="relative mb-3 w-full max-w-[90%] sm:max-w-xs">
              <input
                required
                className="w-full px-3 py-2 border rounded-lg text-md  border-gray-300  shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600"
              >
                <i className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
              </span>
            </div>

            {error && <p className="text-red-500 text-sm mb-3">{error}</p>}

            <button
              type="submit"
              className="w-full max-w-[90%] sm:max-w-xs bg-[#1171bb] mt-6 mb-8 text-white py-2 rounded-lg text-md font-semibold transition"
            >
              Login
            </button>

          </form>

        </div>
      </div>
    </div>


  );
};

export default Login;
