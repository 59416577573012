import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ReportModal = ({ report, onClose }) => {
    if (!report) return null;

    const formattedDate = new Date(report.reportDate).toLocaleDateString('en-GB');

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(16);
        doc.text(`Report for ${report.companyName }`, 14, 20);

        doc.setFontSize(12);
        const tableColumn = ["Week", "Details"];
        const tableRows = [
            ["Week 1", `Post: ${report.week1.post}\nVideo: ${report.week1.video}`],
            ["Week 2", `Post: ${report.week2.post}\nVideo: ${report.week2.video}`],
            ["Week 3", `Post: ${report.week3.post}\nVideo: ${report.week3.video}`],
            ["Week 4", `Post: ${report.week4.post}\nVideo: ${report.week4.video}`],
            ["Monthly Banner", `Banner: ${report.monthlyBanner}`],
            ["Report Date", `Date: ${formattedDate}`],
        ];

        doc.autoTable(tableColumn, tableRows, { startY: 30 });
        doc.save(`Report_for_${report.companyName }.pdf`);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4 sm:px-6 lg:px-8">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl h-auto max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-800">
                        Report for {report.companyName }
                    </h3>
                    <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={onClose}
                        aria-label="Close modal"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                
                {/* Table for report */}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-300">
                        <tbody>
                            <tr>
                                <td className="px-6 py-4 text-sm font-medium text-gray-900 border-b border-gray-200">Week 1</td>
                                <td className="px-6 py-4 text-sm text-gray-600 border-b border-gray-200">
                                    Post: {report.week1.post}<br />
                                    Video: {report.week1.video}
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 text-sm font-medium text-gray-900 border-b border-gray-200">Week 2</td>
                                <td className="px-6 py-4 text-sm text-gray-600 border-b border-gray-200">
                                    Post: {report.week2.post}<br />
                                    Video: {report.week2.video}
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 text-sm font-medium text-gray-900 border-b border-gray-200">Week 3</td>
                                <td className="px-6 py-4 text-sm text-gray-600 border-b border-gray-200">
                                    Post: {report.week3.post}<br />
                                    Video: {report.week3.video}
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 text-sm font-medium text-gray-900 border-b border-gray-200">Week 4</td>
                                <td className="px-6 py-4 text-sm text-gray-600 border-b border-gray-200">
                                    Post: {report.week4.post}<br />
                                    Video: {report.week4.video}
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 text-sm font-medium text-gray-900 border-b border-gray-200">Monthly Banner</td>
                                <td className="px-6 py-4 text-sm text-gray-600 border-b border-gray-200">
                                    Banner: {report.monthlyBanner}
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 text-sm font-medium text-gray-900">Report Date</td>
                                <td className="px-6 py-4 text-sm text-gray-600">
                                    Date: {formattedDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Button Section */}
                <div className="mt-6 flex justify-end space-x-4">
                    <button
                        className="bg-gray-600 text-white hover:bg-gray-700 px-3 sm:px-4 py-2 rounded-lg border border-gray-600"
                        onClick={generatePDF}
                    >
                        Download PDF
                    </button>
                    <button
                        className="bg-gray-500 text-white hover:bg-gray-600 px-3 sm:px-4 py-2 rounded-lg border border-gray-500"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReportModal;
