import React, { useState, useEffect } from "react";
import axios from "axios";
import API_BASE_URL from "../../ApiConfig/ApiConfig";

const AddReport = () => {
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({
    companyName: "",
    week1Post: "",
    week1Video: "",
    week2Post: "",
    week2Video: "",
    week3Post: "",
    week3Video: "",
    week4Post: "",
    week4Video: "",
    monthlyBanner: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/tasks/Search`)
      .then((response) => {
        const companyNames = response.data.map((client) => client.companyName);
        setClients([...companyNames]);
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error on change
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.companyName)
      newErrors.companyName = "Client name is required.";
    for (let week = 1; week <= 4; week++) {
      if (!formData[`week${week}Post`])
        newErrors[`week${week}Post`] = `Week ${week} post is required.`;
      if (!formData[`week${week}Video`])
        newErrors[`week${week}Video`] = `Week ${week} video is required.`;
    }
    if (!formData.monthlyBanner)
      newErrors.monthlyBanner = "Monthly banner is required.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const reportData = {
      companyName: formData.companyName,
      week1: {
        post: formData.week1Post,
        video: formData.week1Video,
      },
      week2: {
        post: formData.week2Post,
        video: formData.week2Video,
      },
      week3: {
        post: formData.week3Post,
        video: formData.week3Video,
      },
      week4: {
        post: formData.week4Post,
        video: formData.week4Video,
      },
      monthlyBanner: formData.monthlyBanner,
      reportDate: new Date().toISOString(),
    };

    axios
      .post(`${API_BASE_URL}/SMMR/reports`, reportData)
      .then((response) => {
        alert("Report submitted successfully");
        // Reset form fields
        setFormData({
          companyName: "",
          week1Post: "",
          week1Video: "",
          week2Post: "",
          week2Video: "",
          week3Post: "",
          week3Video: "",
          week4Post: "",
          week4Video: "",
          monthlyBanner: "",
        });
        setErrors({});
      })
      .catch((error) => {
        console.error("Error submitting report:", error);
        alert("Error submitting report");
      });
  };

  return (
    <div className="min-w-full mx-auto md:mt-4 mt-10 p-6 bg-white rounded-3xl shadow-2xl" style={{ maxWidth: '900px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 20px' }}>
    <h2 className="text-2xl font-bold text-gray-700 mb-4">Add Monthly Report</h2>
    <hr className="border-t border-gray-300 mx-[-24px]" />
  
    <div className="p-6 rounded-md mt-4 border-dashed border border-gray-300">
      <form onSubmit={handleSubmit} className="space-y-6 mt-2">
        {/* Company Name Dropdown */}
        <div>
          <label className="block text-lg font-medium text-gray-700 mb-2">Company Name</label>
          <select
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 ${errors.companyName ? "border-red-500" : "border-gray-300"}`}
            required
          >
            <option value="" className="font-semibold">Select Company Name</option>
            {clients.map((client, index) => (
              <option key={index} value={client}>
                {client}
              </option>
            ))}
          </select>
          {errors.companyName && (
            <p className="text-red-500 text-sm mt-1">{errors.companyName}</p>
          )}
        </div>
  
        {/* Weekly Reports - Two columns with Week 1 & 2 on the first row, Week 3 & 4 on the next */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6">
          {[1, 2, 3, 4].map((week) => (
            <div key={week} className="space-y-4">
              <h3 className="text-xl font-semibold text-sky-700 mb-4">Week {week}</h3>
              <div>
                <label className="block text-lg font-semibold mb-2 text-gray-700">Post</label>
                <input
                  type="text"
                  name={`week${week}Post`}
                  value={formData[`week${week}Post`]}
                  onChange={handleChange}
                  className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 ${errors[`week${week}Post`] ? "border-red-500" : "border-gray-300"}`}
                />
                {errors[`week${week}Post`] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[`week${week}Post`]}
                  </p>
                )}
              </div>
  
              <div>
                <label className="block text-lg font-semibold mb-2 text-gray-700">Video</label>
                <input
                  type="text"
                  name={`week${week}Video`}
                  value={formData[`week${week}Video`]}
                  onChange={handleChange}
                  className={`w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 ${errors[`week${week}Video`] ? "border-red-500" : "border-gray-300"}`}
                />
                {errors[`week${week}Video`] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[`week${week}Video`]}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
  
      {/* Monthly Banner Field */}
      <div>
        <h3 className="text-xl sm:text-xl font-semibold text-sky-700 mt-8 mb-4">
          Monthly Banner
        </h3>
        <label className="block text-lg font-semibold mb-2 text-gray-700">
          Banner
        </label>
        <input
          type="text"
          name="monthlyBanner"
          value={formData.monthlyBanner}
          onChange={handleChange}
          className={`  font-semibold w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 ${
            errors.monthlyBanner ? "border-red-500" : "border-gray-300"
          } `}
        />
        {errors.monthlyBanner && (
          <p className="text-red-500 text-sm mt-1">{errors.monthlyBanner}</p>
        )}
      </div>
      
      {/* Submit Button */}
      <div className=" flex justify-end mt-6">
        <button
          type="submit"
           className="bg-[#006CB5] text-white font-semibold py-2 px-8 rounded-[8px] hover:bg-blue-700 transition duration-300 shadow-lg w-full sm:w-auto "
        >
          Submit 
        </button>
      </div>

      </form>
   
      </div>
    
  
</div>
  
  );
};

export default AddReport;
