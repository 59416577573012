import React, { useState } from 'react';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
const AddUserForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    name: '',
    profileImage: null, // Initialize with null to store file
    role: '',
    address: '',
    designation: '',
    dob: '',
    bloodGroup: '',
    officialEmail: '',
    personalEmail: '',
    empJoiningDate: '',
    phone: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, profileImage: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    for (const key in formData) {
      if (formData[key] !== null && formData[key] !== '') {
        data.append(key, formData[key]);
      }
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/register`, {
        method: 'POST',
        body: data,
      });

      if (!response.ok) {
        // Check if response is not OK and attempt to read JSON error message
        let errorMessage = 'Registration failed';
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || errorMessage;
        } catch (jsonError) {
          console.error('Error parsing JSON response:', jsonError);
        }
        throw new Error(errorMessage);
      }

      // Successful response parsing
      const result = await response.json();
      setMessage(result.message || 'User registered successfully');
      setFormData({
        username: '',
        password: '',
        name: '',
        profileImage: null,
        role: '',
        address: '',
        designation: '',
        dob: '',
        bloodGroup: '',
        officialEmail: '',
        personalEmail: '',
        empJoiningDate: '',
        phone: '',
      });
    } catch (error) {
      console.error('Error:', error);
      setMessage(error.message || 'An unexpected error occurred');
    }
  };

  return (
    <form
    onSubmit={handleSubmit}
    className="min-w-full mt-3 h-472 p-8 md:mt-4 shadow-md bg-white rounded-3xl md:block"
    style={{ maxWidth: '900px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 20px', borderRadius: '30px' }}
  >
    <h2 className="text-2xl font-bold mb-6 text-gray-800">Register New User</h2>
    <hr className="border-t border-gray-300 mx-[-24px] mb-6" />

    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 px-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Username</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          placeholder="Enter your username"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Enter your password"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter your full name"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Role</label>
        <select
          name="role"
          value={formData.role}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        >
          <option value="">Select Role</option>
          <option value="admin">Admin</option>
          <option value="web">Web Developer</option>
          <option value="social">Social Media</option>
          <option value="user">User</option>
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Address</label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Enter your address"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Designation</label>
        <input
          type="text"
          name="designation"
          value={formData.designation}
          onChange={handleChange}
          placeholder="Enter your designation"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Date of Birth</label>
        <input
          type="date"
          name="dob"
          value={formData.dob}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Blood Group</label>
        <select
          name="bloodGroup"
          value={formData.bloodGroup}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        >
          <option value="">Select Blood Group</option>
          <option value="O+">O+</option>
          <option value="O-">O-</option>
          <option value="A+">A+</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B-">B-</option>
          <option value="AB+">AB+</option>
          <option value="AB-">AB-</option>
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Official Email</label>
        <input
          type="email"
          name="officialEmail"
          value={formData.officialEmail}
          onChange={handleChange}
          placeholder="Enter official email"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Personal Email</label>
        <input
          type="email"
          name="personalEmail"
          value={formData.personalEmail}
          onChange={handleChange}
          placeholder="Enter personal email"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Employee Joining Date</label>
        <input
          type="date"
          name="empJoiningDate"
          value={formData.empJoiningDate}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Enter phone number"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
        />
      </div>
    </div>

    <div className="mb-6 px-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">Profile Image</label>
      <input
        type="file"
        name="profileImage"
        onChange={handleFileChange}
        className="w-full p-2 border border-gray-300 rounded-md shadow-sm outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
      />
    </div>

    <div className="md:col-span-2 pt-6 mt-2 flex justify-end">
      <button
        type="submit"
        className="bg-[#006CB5] text-white font-semibold py-2 px-8 rounded-[8px] transition duration-300 shadow-lg w-full sm:w-auto"
      >
        Submit
      </button>
    </div>

    {message && <p className="mt-4 text-center text-red-500">{message}</p>}
  </form>

  );
};

export default AddUserForm;