import React, { useEffect, useState } from 'react';
import UserItem from './UserItem';
import API_BASE_URL from '../../ApiConfig/ApiConfig';
const UserList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/users`);
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  return (
    <div >

      <ul className="divide-y divide-gray-200">
         
      <div className="flex justify-between items-center  pb-2 mb-4">
        <div className="flex-1 text-left font-semibold text-[#B5B7C0]">Employee Name</div>

        <div className="flex-1 text-left font-semibold text-[#B5B7C0] pl-4">Designation</div> 
        <div className="flex-1 text-left font-semibold text-[#B5B7C0] pl-4">Role</div> 
        <div className="flex-1 text-left font-semibold text-[#B5B7C0] pl-4">Progress</div>
        <div className="flex-1 text-right font-semibold text-[#B5B7C0]">Action</div>
      </div> 

       
        {users.map((user) => (
          <UserItem key={user._id} user={user} fetchUsers={fetchUsers} />
        ))}
      </ul>
    </div>
  );
};

export default UserList;
